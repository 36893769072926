<script>

    import CenteredCard from "../Ui/Cards/CenteredCard.svelte";
</script>


<div class="flex items-center p-6 min-h-screen  bg-gray-50">
    <CenteredCard>
        <div class="flex flex-col overflow-y-auto md:flex-row">
            <div class="h-32 md:h-auto md:w-1/2">
                <img aria-hidden="true" class="object-cover w-full h-full" src="/img/login.jpg"
                     alt="Office" style="object-position: left">
            </div>
            <div class="flex items-center justify-center p-6 sm:p-12 md:w-1/2">
                <div class="w-full">
                    <slot/>
                </div>
            </div>

        </div>
    </CenteredCard>

</div>
