<script>
    import SectionTitle from "../Typography/SectionTitle.svelte";
</script>
<div class="md:grid md:grid-cols-4 md:gap-6">
    <SectionTitle>
        <slot name="title" slot="title"/>
        <slot name="description" slot="description"/>
    </SectionTitle>

    <div class="mt-5 md:mt-0 md:col-span-3">
        <div class="shadow overflow-hidden sm:rounded-md">

            <div
                class="px-4 py-5 sm:p-6 bg-white  text-gray-900 ">
                <slot name="content"/>
            </div>
            <div class="flex items-center  px-4 py-3 bg-gray-50  text-right sm:px-6">
                <slot name="back"/>
                <div class="flex-grow"></div>
                <slot name="actions"/>
            </div>
        </div>
    </div>
</div>
