export function isToday(date) {
    if (null === date) return false;
    if (undefined === date) return false;

    date = new Date(date)
    let now = new Date()
    return date.getDay() === now.getDay() && date.getMonth() === now.getMonth() && date.getFullYear() === now.getFullYear();
}

export function localDate(date, options) {
    if(!date) return null;
    date = new Date(date)
    options = options || {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
    }

    return date.toLocaleDateString('de-at', options)
}
export function localDateTime(date, options) {
    if(!date) return null;
    date = new Date(date)
    options = options || {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric'
    }

    return date.toLocaleDateString('de-at', options)
}
export function localDateShort(date, options) {
    if(typeof date == "string"){
        date = date.split(" ")[0]
    }
    return localDate(date, {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
    })
}

export function localDateDayAndMonth(date, options) {
    return localDate(date, {
        month: '2-digit',
        day: '2-digit',
    })
}

export function localTime(date, options) {
    if(!date) return null;
    date = new Date(date)
    options = options || {
        hour: 'numeric',
        minute: 'numeric'
    }

    return date.toLocaleTimeString('de-at', options)
}

export function isoDate(date){
    if(!date) return null;
    date = new Date(date)
    return `${date.getFullYear()}-${zeroFill(date.getMonth()+1)}-${zeroFill(date.getDate())}`;
}


function zeroFill(val) {
    if (val < 10 && val >= 0) {
        return "0" + val;
    }
    return val;
}

export function hoursToTime(hours) {
    let fullHours = Math.floor(hours);
    return `${zeroFill(fullHours)}h ${zeroFill(Math.ceil((hours - fullHours) * 60))}m`
}


export function hoursToTimeStamp(hours) {
    let fullHours = Math.floor(hours);
    return `${zeroFill(fullHours)}:${zeroFill(Math.ceil((hours - fullHours) * 60))}`
}
export function hoursToFixed(hours){
    return `${hours ? hours.toFixed(2) : 0}<span class="unselectable">h</span>`.replace('.', ',');
}
export function lastWeek(){

}

export function year(date = new Date()){
    return new Date(date).getFullYear()
}

export const months =  {
    1: "Jänner",
    2: "Februar",
    3: "März",
    4: "April",
    5: "Mai",
    6: "Juni",
    7: "Juli",
    8: "August",
    9: "September",
    10: "Oktober",
    11: "November",
    12: "Dezember",
};
