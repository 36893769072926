<script>
    import PageTitle from "../../../Ui/Typography/PageTitle.svelte";
    import FormSection from "../../../Ui/Layout/FormSection.svelte";
    import Field from "../../../Ui/Form/Field.svelte";
    import PrimaryButton from "../../../Ui/Interactive/PrimaryButton.svelte";
    import {page, useForm} from "@inertiajs/svelte";
    import Route from "../../../Ui/Route.svelte";
    import Label from "../../../Ui/Form/Label.svelte";
    import {user} from "../../../stores/page.js";
    import Stack from "../../../Ui/Layout/Stack.svelte";
    import Alert from "../../../Ui/Display/Alert.svelte";

    export let category;
    let form = useForm({
        _method: 'PUT',
        name: category.name,
        update_slug:false,
        redirect_with_consent: category.redirect_with_consent,
        redirect_without_consent: category.redirect_without_consent,
        image: null,
        booking_code: category.booking_code,
        newsletter_list: category.newsletter_list,
    });

    function update() {
        if($form.image && $form.image.length){
            $form.image = $form.image[0]
        }
        $form.post(route("disclaimer.category.update", category.id),{
            forceFormData: true,
        });
    }

</script>

<div class="max-w-7xl mx-auto py-10 sm:px-6 lg:px-8">
    <FormSection on:submit={update}>
            <span slot="title">
                <PageTitle title="Kategorie bearbeiten"/>
            </span>
        <span slot="description">Ändern Sie die Kategorie.</span>

        <div slot="form">
            <Stack>
                <Field bind:form name="name" label="Name"/>
                <div class="text-sm">
                    Slug: {category.slug}
                </div>
                <Field bind:form name="update_slug" label="Slug ändern?" type="checkbox"/>

                {#if $form.update_slug}
                    <Alert type="warning" title="Slug ändern" message="Wenn der Slug geändert wird, ändern sich alle öffentlichen Links!"/>
                {/if}

                <Field bind:form name="booking_code" label="Name in Buchungsbestätigungs-Mail"/>
                <Field bind:form name="redirect_with_consent" label="Redirect Url nach Abschluss mit Marketing Consent"/>
                <Field bind:form name="redirect_without_consent" label="Redirect Url nach Abschluss ohne Marketing Consent"/>
                <Field bind:form name="newsletter_list" label="Abweichende Newsletter Liste"/>

                {#if category.image_url}
                    <div class="p-4 bg-primary">
                        <img src="{category.image_url}" class="h-24 w-auto mx-auto"/>
                    </div>
                {/if}
                <Field bind:form name="image" label="Neues Kategoriebild" type="file"/>
            </Stack>
        </div>

        <Route to="disclaimer.category.index" slot="back">
            Abbrechen
        </Route>
        <div slot="actions">
            <PrimaryButton type="submit" disabled={$form.processing}>
                Speichern
            </PrimaryButton>
        </div>

    </FormSection>

</div>
