<script>
    import EditorJS from '@editorjs/editorjs';
    import Header from '@editorjs/header';
    import List from '@editorjs/list';
    import Paragraph from '@editorjs/paragraph';
    import edjsHTML from 'editorjs-html'

    import {onMount} from "svelte";

    export let data;
    export let content;

    let holder
    onMount(() => {

        const edjsParser = edjsHTML();

        const editor = new EditorJS({
            holder: holder,
            tools: {
                header: {
                    class: Header,
                    inlineToolbar : true
                },
                paragraph: {
                    class: Paragraph,
                    inlineToolbar: true
                },
                list: {
                    class: List,
                    inlineToolbar: true
                }
            },
            onChange(api, event) {
                editor.save().then((outputData) => {
                    data = JSON.stringify(outputData)
                    content = edjsParser.parse(outputData).join("\n");
                })
            },
            data: JSON.parse(data)
        });


        return () => {
            editor.destroy()
        }
    })
</script>
<div class="border border-gray-300 focus:border-primary-600 focus:ring focus:ring-primary-400 focus:ring-opacity-50 rounded-md bg-white   text-gray-900  placeholder-gray-400 ">
    <div bind:this={holder} class="prose max-w-auto pl-14">

    </div>
</div>
