<script>
    import { fade, fly } from 'svelte/transition';

    let listener = null;
    let open = false;
    export let contentClasses = 'py-1 bg-white';
    export let width = 48;
    export let align = 'left'
    let widthClass = 'w-'+width;
    let alignmentClasses = (() => {
        if (align === 'left') {
            return 'origin-top-left left-0'
        } else if (align === 'right') {
            return 'origin-top-right right-0'
        } else {
            return 'origin-top'
        }
    })()


    function closeOnEscape(e){
        if (open && e.keyCode === 27) {
            open = false
        }
    }

    function escListener(on){
        document.removeEventListener('keydown', closeOnEscape)
        if(on) {
            document.addEventListener('keydown', closeOnEscape)
        }
    }
    $: escListener(open)
</script>
<div class="relative">
    <button on:click={() => open = ! open}>
        <slot name="trigger"/>
    </button>

    <!-- Full Screen Dropdown Overlay -->
    {#if open}
    <button class="fixed inset-0 z-40" on:click={() => open = false}>
    </button>
    {/if}

    {#if open}
        <button transition:fly={{y: -10, duration: 200}}
            class:hidden={!open}
             class="absolute z-50 mt-2 rounded-md shadow-lg hidden {widthClass} {alignmentClasses}"
             on:click={() => open = false}>
            <div class="rounded-md ring-1 ring-black ring-opacity-5 bg-white  {contentClasses}">
                <slot />
            </div>
        </button>
    {/if}

</div>
