<script>

    import {onDestroy, onMount} from "svelte";
    import {clickOutside} from '../../lib/clickOutside.js';
    import {isEmpty} from 'lodash';
    import {router} from "@inertiajs/svelte";

    let inputRef;
    let listener = null;

    function onKeyDown(evt){{
        if (evt.isComposing) {
            return;
        }

        let ctrlDown = evt.ctrlKey||evt.metaKey
        if(ctrlDown && evt.key === 'k' ) {//k
            inputRef.focus()
            evt.preventDefault();
        }
        // do something
    }}
    onMount(() => {
        document.addEventListener("keydown",onKeyDown);
    })

    onDestroy(() => {
        document.removeEventListener("keydown", onKeyDown);
    })

    let show = false;
    let timer;
    let searchQuery = '';
    $: search(searchQuery)
    let results = {}
    $: hasResults = !isEmpty(results)

    function search(q){
        router.get(route('disclaimer.group.index'), {q: q}, { replace: true })
    }


    const typeNames = {
        'bitrixCrm': 'Bitrix',
        'outline': 'Dokumentation',
    }
    const bitrixTypes = {
        CUSTOMER: 'Kunde',
        CONTACT: 'Kontakt',
    }
    function handleClickOutside(){
        show = false
    }
</script>

<div class="flex justify-center flex-1 lg:mr-32" use:clickOutside on:click_outside={handleClickOutside}>
    <div class="relative w-full max-w-xl mr-6 focus-within:text-primary-500">
        <div class="absolute inset-y-0 flex items-center pl-2">
            <svg class="w-4 h-4" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20">
                <path fill-rule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clip-rule="evenodd"></path>
            </svg>
        </div>
        <input class="w-full pl-8 pr-2 text-sm text-gray-700 placeholder-gray-600 bg-gray-100 border-0 rounded-md  :shadow-outline-gray :placeholder-gray-600   focus:placeholder-gray-500 focus:bg-white focus:border-primary-300 focus:outline-none focus:shadow-outline-primary form-input" type="text" placeholder="Suche" aria-label="Search" bind:value={searchQuery} bind:this={inputRef} on:click={() => show = true}>
    </div>
</div>


