<script>
    import PageTitle from "../../../Ui/Typography/PageTitle.svelte";
    import FormSection from "../../../Ui/Layout/FormSection.svelte";
    import Field from "../../../Ui/Form/Field.svelte";
    import PrimaryButton from "../../../Ui/Interactive/PrimaryButton.svelte";
    import {useForm} from "@inertiajs/svelte";
    import Route from "../../../Ui/Route.svelte";
    import Stack from "../../../Ui/Layout/Stack.svelte";

    let form = useForm({
        name: null,
    });
    const submit = () => $form.post(route("disclaimer.disclaimerEntry.store"));

</script>
<div class="max-w-7xl mx-auto py-10 sm:px-6 lg:px-8">
    <FormSection on:submit={submit}>
        <div slot="title">
            <PageTitle title="DisclaimerEntry erstellen"/>
        </div>
        <div slot="description">
            Legen Sie einen Eintrag an.
        </div>

        <div slot="form">
            <Stack>
                <Field bind:form name="name" label="Name"/>
            </Stack>
        </div>

        <Route to="disclaimer.disclaimerEntry.index" slot="back">
            Abbrechen
        </Route>
        <svelte:fragment slot="actions">
            <PrimaryButton type="submit">
                Erstellen
            </PrimaryButton>
        </svelte:fragment>

    </FormSection>

</div>
