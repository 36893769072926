<script>
    import DangerButton from "./DangerButton.svelte";
    import {createEventDispatcher} from "svelte";
    import {clickOutside} from '../../lib/clickOutside.js';
    import PrimaryButton from "./PrimaryButton.svelte";

    export let title;
    export let open = false;
    export let confirm = "Ok"
    export let type = "info" // info, warning, danger
    export let inModal = false;

    const dispatch = createEventDispatcher();
</script>

<div class="z-40 fixed inset-0 bg-gray-100 bg-opacity-80" class:hidden={!open}>
    <dialog open={open ? 'open' : undefined} class:md:ml-64={!inModal}
            class="z-40 w-full bg-transparent fixed bg-white left-0 top-0 mt-12 mx-auto"
            use:clickOutside on:click_outside={() => open=false}>

        <div class="shadow-lg bg-white">
            <div class="text-lg mb-2 px-3 font-semibold py-2">
                {title}
            </div>

            <div class="px-2">
                <slot/>
            </div>
            <div class="actions flex mt-4 px-3 pb-3">
                <button class="underline text-gray-500" on:click={() => open = false}>
                    Abbrechen
                </button>
                {#if type === 'danger'}
                    <DangerButton class="ml-auto" on:click={() => dispatch('confirm')}>
                        {confirm}
                    </DangerButton>
                {:else}
                    <PrimaryButton class="ml-auto" on:click={() => dispatch('confirm')}>
                        {confirm}
                    </PrimaryButton>
                {/if}
            </div>
        </div>
    </dialog>
</div>


<style>

    @media (min-width: 768px) {
        dialog {
            width: calc(100vw - 16rem);
        }
    }
</style>
