<script>
    import Overlay from "svelte-overlay";
    import {router} from "@inertiajs/svelte";
    import Route from "../../Ui/Route.svelte";
    import {page} from "@inertiajs/svelte";
    import ProfilePhoto from "../../Components/Users/ProfilePhoto.svelte";
    import GlobalSearch from "../../Components/Layout/GlobalSearch.svelte";
    import Logout from "../../Icons/Logout.svelte";
    import User from "../../Icons/User.svelte";

    export let title = "";
    export let sideMenuOpen;

    function toggleSideMenu() {
        sideMenuOpen = !sideMenuOpen;
    }

    let isProfileMenuOpen = false;

    // const toggleProfileMenu = () => isProfileMenuOpen = !isProfileMenuOpen
    function handleKeydown(event) {
        if (event.key === "Escape") {
            isProfileMenuOpen = false;
        }
    }

    const logout = () => router.post(route("logout"));
</script>
<header class="z-10 py-4 bg-white shadow-md sticky top-0 mb-4">
    <div class="container flex flex-col">
    <div class="w-full flex items-center justify-between h-full px-6 mx-auto text-primary-600 ">
        <!-- Mobile hamburger -->
        <button class="p-1 mr-5 -ml-1 rounded-md md:hidden focus:outline-none focus:shadow-outline-primary" on:click={toggleSideMenu} aria-label="Menu">
            <svg class="w-6 h-6" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20">
                <path fill-rule="evenodd" d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clip-rule="evenodd"></path>
            </svg>
        </button>
        <!-- Search input -->
        <GlobalSearch/>
        <ul class="flex items-center flex-shrink-0 space-x-6">

            <!-- Profile menu -->
            <li class="relative">
                <Overlay
                    onWindowKeyDown={handleKeydown}
                    closeOnClickOutside
                    bind:isOpen={isProfileMenuOpen}>

                    <button slot="parent" let:toggle on:click={toggle} class="align-middle rounded-full focus:shadow-outline-primary focus:outline-none" aria-label="Account" aria-haspopup="true">
                        <ProfilePhoto src={$page.props.user.profile_photo_url} size="md" attendance={$page.props.user.attendanceState}/>
                    </button>

                    <div slot="content" class="content" let:close>

                        <ul class=" right-0 w-56 p-2 mt-2 space-y-2 text-gray-600 bg-white border border-gray-100 rounded-md shadow-md   " aria-label="submenu">
                            <li class="flex">
                                <Route to="profile.show" class="inline-flex gap-2 items-center w-full px-2 py-1 text-sm font-semibold transition-colors duration-150 rounded-md hover:bg-gray-100 hover:text-gray-800 :bg-gray-800 :text-gray-200">
                                    <User mono={true}/>
                                    <span>Profil</span>
                                </Route>
                            </li>

<!--                            <li class="flex">-->
<!--                                <a class="inline-flex items-center w-full px-2 py-1 text-sm font-semibold transition-colors duration-150 rounded-md hover:bg-gray-100 hover:text-gray-800 :bg-gray-800 :text-gray-200" href="#">-->
<!--                                    <svg class="w-4 h-4 mr-3" aria-hidden="true" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" stroke="currentColor">-->
<!--                                        <path d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z"></path>-->
<!--                                        <path d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"></path>-->
<!--                                    </svg>-->
<!--                                    <span>Settings</span>-->
<!--                                </a>-->
<!--                            </li>-->

                            <li class="flex">
                                <form on:submit|preventDefault={logout} class="w-full">
                                    <button class="inline-flex gap-2  items-center w-full px-2 py-1 text-sm font-semibold transition-colors duration-150 rounded-md hover:bg-gray-100 hover:text-gray-800 :bg-gray-800 :text-gray-200" href="#">
                                        <Logout mono={true}/>
                                        <span>Abmelden</span>
                                    </button>
                                </form>

                            </li>
                        </ul>
                    </div>
                </Overlay>


            </li>

            {#if $page.props.impersonating}
                <li >
                    <Route to="impersonate.leave" target="_self" title="Impersonate beenden">
                        <Logout/>
                    </Route>
                </li>
            {/if}
        </ul>
    </div>

        <div class="px-4 text-xl">
            {title}
        </div>
    </div>
</header>
