<script>
    import Input from "./Input.svelte";
    import {createEventDispatcher} from "svelte";

    export let value = null;
    let clazz;
    export {clazz as class};

    const dispatch = createEventDispatcher();

    $: date = value ? datePart(value) : null;
    $: time = value ? timePart(value) : null;

    function datePart(val) {
        let parts = val.split(" ");
        return parts[0];
    }

    function timePart(val) {
        let parts = val.split(" ");
        return parts[1];
    }

    function setDate(e) {
        value = e.target.value + " " + (time ?? "00:00");
        dispatch('input')
    }

    function setTime(e) {
        value = date + " " + e.target.value;
        dispatch('input')
    }

    let inputProps = $$restProps;
    inputProps.type = null;

</script>
<div class="{clazz} flex gap-2 ">
    <Input type="date" pattern="\d{4}-\d{2}-\d{2}" class="flex-1" value={date} on:input={setDate}/>

    <Input type="time" pattern="\d{2}:\d{2}" class="flex-1" value={time} on:input={setTime}/>
</div>

