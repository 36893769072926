<script>

import FormSection from "../../Ui/Layout/FormSection.svelte";
import {useForm} from "@inertiajs/svelte";
import PrimaryButton from "../../Ui/Interactive/PrimaryButton.svelte";
import Label from "../../Ui/Form/Label.svelte";
import InputError from "../../Ui/Form/InputError.svelte";

let files = null;
let form = useForm({
    image: null
});

function submit(){
    $form.post(route('profile.image.set'), {
        forceFormData: true,
    })
}
</script>

<FormSection on:submit={submit}>

    <slot slot="title">Profilbild ändern</slot>
    <slot slot="description">Ändern Sie ihr Profilbild.</slot>

    <slot slot="form">

        <Label>
            Neues Profilbild wählen
        </Label>
        <input type="file" name="image" on:input={e => $form.image = e.target.files[0]}>
        <InputError message={$form.errors.image}/>

    </slot>

    <slot slot="actions">
        <PrimaryButton disabled={$form.processing} type="submit">
            Speichern
        </PrimaryButton>
    </slot>
</FormSection>
