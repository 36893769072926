<script>
    import PageTitle from "../../../Ui/Typography/PageTitle.svelte";
    import FormSection from "../../../Ui/Layout/FormSection.svelte";
    import Field from "../../../Ui/Form/Field.svelte";
    import PrimaryButton from "../../../Ui/Interactive/PrimaryButton.svelte";
    import {useForm} from "@inertiajs/svelte";
    import Route from "../../../Ui/Route.svelte";
    import Stack from "../../../Ui/Layout/Stack.svelte";

    let form = useForm({
        name: null,
        redirect_with_consent: null,
        redirect_without_consent: null,
        image: null,
        booking_code: null,
    });
    function submit(){
        if($form.image && $form.image.length){
            $form.image = $form.image[0]
        }
        $form.post(route("disclaimer.category.store"),{
            forceFormData: true,
        });
    }


</script>
<div class="max-w-7xl mx-auto py-10 sm:px-6 lg:px-8">
    <FormSection on:submit={submit}>
        <div slot="title">
            <PageTitle title="Category erstellen"/>
        </div>
        <div slot="description">
            Legen Sie eine Kategorie an.
        </div>

        <div slot="form">
            <Stack>
                <Field bind:form name="name" label="Name"/>
                <Field bind:form name="booking_code" label="Name in Buchungsbestätigungs-Mail"/>
                <Field bind:form name="redirect_with_consent" label="Redirect Url nach Abschluss mit Marketing Consent"/>
                <Field bind:form name="redirect_without_consent" label="Redirect Url nach Abschluss ohne Marketing Consent"/>
                <Field bind:form name="image" label="Kategoriebild" type="file"/>
            </Stack>
        </div>

        <Route to="disclaimer.category.index" slot="back">
            Abbrechen
        </Route>
        <svelte:fragment slot="actions">
            <PrimaryButton type="submit">
                Erstellen
            </PrimaryButton>
        </svelte:fragment>

    </FormSection>

</div>
