<script>
    import ListSection from "../../../Ui/Layout/ListSection.svelte";
    import FilterSelect from "../../../Ui/Interactive/FilterSelect.svelte";
    import SearchBar from "../../../Ui/Interactive/SearchBar.svelte";
    import Container from "../../../Ui/Layout/Container.svelte";
    import TableHeader from "../../../Ui/Table/TableHeader.svelte";
    import SortableLink from "../../../Ui/Interactive/SortableLink.svelte";
    import TableAction from "../../../Ui/Table/TableAction.svelte";
    import PrimaryButton from "../../../Ui/Interactive/PrimaryButton.svelte";
    import Print from "../../../Icons/Print.svelte";
    import Key from "../../../Icons/Key.svelte";
    import TableRow from "../../../Ui/Table/TableRow.svelte";
    import TableColumn from "../../../Ui/Table/TableColumn.svelte";
    import PageTitle from "../../../Ui/Typography/PageTitle.svelte";
    import Stack from "../../../Ui/Layout/Stack.svelte";
    import Pencil from "../../../Icons/Pencil.svelte";

    export let textblocks;
</script>
<Container>
    <ListSection list={ textblocks }>
        <div slot="title">
            <PageTitle title="Textblock"/>
        </div>
        <div slot="description">Textblockverwaltung</div>

        <div slot="actions">
            <Stack>
                <SearchBar/>
            </Stack>
        </div>

        <TableHeader slot="header">
            <th>
                Name
            </th>
            <th>
                Schlüssel
            </th>
            <th class="w-20"></th>
        </TableHeader>

        <TableRow slot="item" let:item>
            <TableColumn>{item.name}</TableColumn>
            <TableColumn>{item.key}</TableColumn>

            <TableColumn class="flex flex-row gap-2 items-center">
                <TableAction  to="text.textblock.edit" args={item.id} icon={Pencil}/>
            </TableColumn>
        </TableRow>


    </ListSection>

</Container>
