<script>
    import FilterIcon from "../../Icons/FilterIcon.svelte";
    import Dropdown from "./Dropdown.svelte";
    import DropdownLink from "./DropdownLink.svelte";
    import Label from "../Form/Label.svelte";
    import ArrowDown from "../../Icons/ArrowDown.svelte";
    import {getUrlParam} from "../../lib/url.js";
    import Checkbox from "../Form/Checkbox.svelte";
    import {createEventDispatcher, onMount} from "svelte";

    export let multi = false;
    export let emptyOption
    export let name
    export let options

    let key = getUrlParam(name) ?? "";
    let value;
    const dispatch = createEventDispatcher();

    onMount(() => {
        let optionEntries = Object.entries(options)
        value = optionEntries.filter((entry) => {
            return key.includes(entry[0])
        }).map((entry) => {
            return entry[1]
        }).join(",");
    })

    function handleFilter(selectedKey, selectedValue) {
        if (multi) {
            let keys = key.split(",")
            let values = value.split(",")

            if (keys.includes(selectedKey)) {
                keys.splice(keys.indexOf(selectedKey), 1);
            } else {
                keys.push(selectedKey)
            }

            if (values.includes(selectedValue)) {
                values.splice(values.indexOf(selectedValue), 1);
            } else {
                values.push(selectedValue)
            }

            value = values.filter(v => !!v).join(",")
            key = keys.filter(v => !!v).join(",")
        } else {
            value = selectedValue;
            key = selectedKey;
        }

        //input.value = key // Trigger on:change event
        //dispatch('change')
    }

    let input;
</script>

<div class="relative cursor-pointer mb-2 mr-2 border border-gray-300 focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 rounded-md shadow-sm">
    <input type="hidden" name={name} bind:this={input} value={key}/>

    <Dropdown width="full">

        <div slot="trigger">
            <div class="absolute inset-y-0 flex items-center pl-2 z-20">
                <FilterIcon/>
            </div>
            <div class="filter-select pl-8 pr-8 py-2 rounded-md shadow-sm bg-white  flex">
                <Label class="w-48 truncate inline-block cursor-pointer">
                    {#if !value}
                        {emptyOption}
                    {:else}
                        {value}
                    {/if}
                </Label>
            </div>

            <div class="absolute flex items-center inset-y-0 right-0 pr-2">
                <ArrowDown/>
            </div>
        </div>
        {#if !multi}
            <DropdownLink as="custom">
                <div class="inline-block w-full">
                    <Label class="cursor-pointer flex items-center">
                        <Checkbox value=""
                                  on:change={() => handleFilter('', '')}
                                  class="hidden"/>
                        <span class="ml-2 flex break-all">{emptyOption}</span>
                    </Label>
                </div>
            </DropdownLink>
        {/if}
        {#each Object.entries(options) as [optionKey, val]}

            <DropdownLink as="custom">
                <div class="inline-block w-full">
                    <Label class="cursor-pointer  flex items-center">
                        <Checkbox checked={key.split(',').includes(optionKey)} value="{optionKey}"
                                  on:change={() => handleFilter(optionKey, val)} class="{multi === true ?'':'hidden'}"/>
                        <span class="ml-2 flex break-all">{val}</span>
                    </Label>
                </div>
            </DropdownLink>

        {/each}
    </Dropdown>
</div>
