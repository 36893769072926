<script>
    import PageTitle from "../../../Ui/Typography/PageTitle.svelte";
    import FormSection from "../../../Ui/Layout/FormSection.svelte";
    import Field from "../../../Ui/Form/Field.svelte";
    import PrimaryButton from "../../../Ui/Interactive/PrimaryButton.svelte";
    import {page, useForm} from "@inertiajs/svelte";
    import Route from "../../../Ui/Route.svelte";
    import Label from "../../../Ui/Form/Label.svelte";
    import {user} from "../../../stores/page.js";
    import Stack from "../../../Ui/Layout/Stack.svelte";

    export let disclaimerEntry;
    let form = useForm(disclaimerEntry);

    function update() {
        $form.put(route("disclaimer.disclaimerEntry.update", disclaimerEntry.id));
    }

</script>

<div class="max-w-7xl mx-auto py-10 sm:px-6 lg:px-8">
    <FormSection on:submit={update}>
            <span slot="title">
                <PageTitle title="DisclaimerEntry bearbeiten"/>
            </span>
        <span slot="description">Ändern Sie DisclaimerEntry.</span>

        <div slot="form">
            <Stack>
                <Field bind:form name="name" label="Name"/>
            </Stack>
        </div>

        <Route to="disclaimer.disclaimerEntry.index" slot="back">
            Abbrechen
        </Route>
        <div slot="actions">
            <PrimaryButton type="submit" disabled={$form.processing}>
                Speichern
            </PrimaryButton>
        </div>

    </FormSection>

</div>
