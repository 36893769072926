<script>
    import PageTitle from "../Ui/Typography/PageTitle.svelte";
    import Container from "../Ui/Layout/Container.svelte";
    import Modal from "../Ui/Modal.svelte";
    import CenteredCard from "../Ui/Cards/CenteredCard.svelte";
    import H2 from "../Ui/Typography/H2.svelte";
    import CloseModal from "../Ui/Interactive/CloseModal.svelte";
    import PrimaryButton from "../Ui/Interactive/PrimaryButton.svelte";
    import Table from "../Ui/Table/Table.svelte";
    import TableHeader from "../Ui/Table/TableHeader.svelte";
    import TableColumn from "../Ui/Table/TableColumn.svelte";
    import TableRow from "../Ui/Table/TableRow.svelte";
    import {localTime} from "../lib/date.js";
    import ArrowUp from "../Icons/ArrowUp.svelte";
    import ArrowDown from "../Icons/ArrowDown.svelte";
    import {router} from "@inertiajs/svelte";
    import GroupRow from "../Components/Dashboard/GroupRow.svelte";
    import {onMount} from "svelte";
    import SearchBar from "../Ui/Interactive/SearchBar.svelte";

    export let categories;
    export let selected_category;
    export let groups;
    export let all_groups;

    $: filteredGroups = filterGroups(groups, search)
    function filterGroups(groups, search) {
        if(!groups) return groups;
        return groups.filter(group => {
            if (!search) return true;
            return group.name.toLowerCase().includes(search.toLowerCase())
        })
    }
    let search = '';
    onMount(() => {
        let interval = setInterval(() => {
            router.reload({only: ['groups']})
        }, 10000)
        return () => {
            clearInterval(interval)
        }
    })
</script>
<div class="hidden">
    <PageTitle title="Dashboard"/>
</div>
<div class="flex gap-4 px-4">
    {#each categories as category(category.id) }
        <PrimaryButton class="px-8 {category.id == selected_category?.id ? 'bg-primary-700' : ''}" route="dashboard"
                       args={{category_id: category.id}}>
            {category.name}
        </PrimaryButton>
    {/each}
    <div class="ml-auto">
        <SearchBar bind:search placeholder="Suche nach Gruppenname"/>
    </div>
</div>
{#if selected_category}
    <div class="p-4">
        <Table class="z-0">
            <TableHeader slot="header">
                <th>Start</th>
                <th>Gruppe</th>
                <th class="text-center">Anzahl</th>
                <th></th>
            </TableHeader>
            {#each filteredGroups as group(group.id)}
                <GroupRow {...group} {groups} {all_groups}/>
            {/each}
        </Table>
    </div>
{/if}

