<script lang="ts">
    // See: https://windmill-dashboard.vercel.app/index.html
    import FlashMessages from "../Components/Layout/FlashMessages.svelte";
    import SidebarMenu from "./Partials/SidebarMenu.svelte";
    import HeaderBar from "./Partials/HeaderBar.svelte";
    import {pageTitle, settings} from "../stores/page.js";

    let showingNavigationDropdown = false;
    let sideMenuOpen = false;
    $: siteName = $settings.general.site_name ?? ""

    let title = siteName;
    $: title = $pageTitle ? `${$pageTitle} | ${siteName}` : siteName;
</script>
<svelte:head>
    <title>{title}</title>
</svelte:head>
<div>
    <div class="flex min-h-screen bg-gray-50 ">
        <SidebarMenu {title} bind:open={sideMenuOpen}/>
        <div class="flex flex-col flex-1 w-full overflow-x-hidden">

            <HeaderBar bind:sideMenuOpen/>

            <slot/>
        </div>
    </div>
</div>
<FlashMessages/>
