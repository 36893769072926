<script>
    import {page} from "@inertiajs/svelte";
    import UpdateProfileInformationForm from "../../Components/Profile/UpdateProfileInformationForm.svelte";
    import Container from "../../Ui/Layout/Container.svelte";
    import PageTitle from "../../Ui/Typography/PageTitle.svelte";
    import UpdatePasswordForm from "../../Components/Profile/UpdatePasswordForm.svelte";
    import SectionBorder from "../../Components/Layout/SectionBorder.svelte";
    import LogoutOtherBrowserSessionForm from "../../Components/Profile/LogoutOtherBrowserSessionForm.svelte";
    import UpdateProfilePhoto from "../../Components/Profile/UpdateProfilePhoto.svelte";

    export let sessions;
</script>

<Container>
    <PageTitle title="Profil"/>
    {#if $page.props.jetstream.canUpdateProfileInformation}
        <UpdateProfileInformationForm user={$page.props.user}/>
        <SectionBorder/>
    {/if}

    <UpdatePasswordForm/>

    <SectionBorder/>

    <UpdateProfilePhoto/>

    <SectionBorder/>

    {#if $page.props.user.hasPassword}
        <LogoutOtherBrowserSessionForm {sessions}/>
    {/if}

</Container>
