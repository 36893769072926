<script>
    import Route from "../Route.svelte";

    export let route = undefined;
    export let href = undefined;
    export let args = undefined;

    export let type = route ? "route" : href ? "href" : "button"
    let clazz = '';
    export {clazz as class};

    let baseClass = 'px-4 py-2 bg-primary border border-transparent rounded-md font-semibold text-sm text-white hover:bg-primary-800 active:bg-primary-900 focus:outline-none focus:border-primary-900 focus:shadow-outline-primary transition-all ease-in-out duration-500 disabled:opacity-50 disabled:cursor-not-allowed text-center hover:tracking-widest'
</script>
{#if type === "route"}
    <Route to={route} {args}
           class="{baseClass} {clazz || ''}"
           {...$$restProps}>
        <slot/>
    </Route>
{:else if type === "href"}
    <a {href} {args}
           class="{baseClass} {clazz || ''}"
           {...$$restProps}>
        <slot/>
    </a>
{:else }
    <button {type}
            class="{baseClass} {clazz || ''}"
            {...$$restProps} on:click>
        <slot/>
    </button>
{/if}
