<script>
    import PageTitle from "../../../Ui/Typography/PageTitle.svelte";
    import FormSection from "../../../Ui/Layout/FormSection.svelte";
    import Field from "../../../Ui/Form/Field.svelte";
    import PrimaryButton from "../../../Ui/Interactive/PrimaryButton.svelte";
    import {useForm} from "@inertiajs/svelte";
    import Route from "../../../Ui/Route.svelte";
    import Stack from "../../../Ui/Layout/Stack.svelte";

    export let categories;
    let form = useForm({
        category_id: Object.keys(categories)[0],
        name: null,
        email: null,
        count_estimated: null,
        start_date: null,
        vip: false,
        is_walk_in: false,
        booking_code: null,
        new: false,
    });

    function submitAndNew() {
        $form.new = true;
        submit();
    }

    async function submit (){
        await $form.post(route("disclaimer.group.store"));
        clear()
    }

    function clear() {
        $form.category_id = Object.keys(categories)[0];
        $form.name = null;
        $form.email = null;
        $form.count_estimated = null;
        $form.start_date = null;
        $form.vip = false;
        $form.is_walk_in = false;
        $form.new = false;
    }

</script>
<div class="max-w-7xl mx-auto py-10 sm:px-6 lg:px-8">
    <FormSection on:submit={submitAndNew}>
        <div slot="title">
            <PageTitle title="Gruppe erstellen"/>
        </div>
        <div slot="description">
            Legen Sie einen Eintrag an.
        </div>

        <div slot="form">
            <Stack>
                <Field bind:form name="category_id" label="Kategorie" type="select" options={categories} required/>
                <Field bind:form name="name" label="Name" required/>
                <Field bind:form name="email" label="E-Mail Adresse ansprechpartner" required/>
                <Field bind:form name="start_date" label="Datum & Uhrzeit" type="datetime" required/>
                <Field bind:form name="count_estimated" label="Voraussichtliche Anzahl"/>
                <Field bind:form name="booking_code" label="Buchungsnummer" />
                <Field bind:form name="vip" label="VIP" type="checkbox"/>
                <Field bind:form name="is_walk_in" label="Walk In Gruppe" type="checkbox"/>

            </Stack>
        </div>

        <Route to="disclaimer.group.index" slot="back">
            Abbrechen
        </Route>
        <svelte:fragment slot="actions">
            <div class="flex gap-2">
                <PrimaryButton type="button" on:click={submit}>
                    Erstellen
                </PrimaryButton>
                <PrimaryButton type="submit">
                    Erstellen & Neue Gruppe
                </PrimaryButton>
            </div>
        </svelte:fragment>

    </FormSection>

</div>
