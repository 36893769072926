<script>
    export let size = "sm";
    export let attendance = null;
    export let src;

    const imageClasses = {
        "sm": "w-6 h-6 border-2",
        "md": "w-8 h-8 border-4",
        "lg": "w-14 h-14 border-4",
    };

    const attendanceClasses = {
        "present": "border-green-400",
        "absent": "border-red-400",
        "paused": "border-yellow-400",
    };

    $: imageClass = imageClasses[size];
    $: attendanceClass = attendance ? attendanceClasses[attendance] : "";

</script>

<img {src}
     class="rounded-full inline employee-image object-cover transition-all duration-700 ease-in-out {imageClass} {attendanceClass}"
     alt="Your Profile">
