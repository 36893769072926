<script>
    export let value
    export let options
    export let emptyOption
    export let emptyOptionDisabled = true
    export let emptyOptionValue = null
    let clazz
    export {clazz as class}

    // $: optionValues = Array.isArray(options) ? Object.assign({}, options) : options
    // $: console.log(optionValues)
    // $: console.log("val", value)
</script>
<select
    class="form-select border-gray-300 focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 rounded-md shadow-sm bg-white  {clazz}"
    bind:value on:change {...$$restProps}>
    {#if emptyOption}
        <option value={emptyOptionValue} selected={emptyOptionValue == null ? 'selected' : undefined} disabled={emptyOptionDisabled}>
            {emptyOption}
        </option>
    {/if}
    {#each Object.entries(options) as [key, val]}
        <option value={""+key} selected={key == value ? 'selected' : undefined}>
            {val}
        </option>
    {/each}
</select>
