<div class="w-full mb-8 overflow-hidden rounded-lg shadow">
    <div class="w-full overflow-x-auto">

        <table class="w-full whitespace-no-wrap">

            <slot name="header"/>

            <tbody class="bg-white divide-y   text-gray-700 ">
            <slot/>
            </tbody>

        </table>
        <slot name="footer"/>

    </div>
</div>
