<script>
    import {createEventDispatcher} from "svelte";

    const dispatch = createEventDispatcher();

    const styles = {
        "success": {
            box: "bg-green-100 border-green-500 text-green-900",
            text: "text-green-500"
        },
        "danger": {
            box: "bg-red-100 border-red-500 text-red-900",
            text: "text-red-500"
        },
        "info": {
            box: "bg-blue-100 border-blue-500 text-blue-900",
            text: "text-blue-500"
        },
        "warning": {
            box: "bg-yellow-100 border-yellow-500 text-yellow-900",
            text: "text-yellow-500"
        }
    };

    export let type, title, message;
    const style = styles[type] ?? styles["info"];
</script>

<div class="{style.box} border-t-4 rounded-b px-4 py-3 shadow-md relative transition transition-all" role="alert">
    <div class="flex">
        <div class="py-1">
            <svg class="{style.text} fill-current h-6 w-6 mr-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                <path
                    d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z"/>
            </svg>
        </div>
        <div>
            <p class="">{title}</p>
            <p class="text-sm">{message}</p>
        </div>
    </div>
    <button class="absolute top-0 bottom-0 right-0 px-4 py-3 cursor-pointer" on:click={() => dispatch('close')}>
        <svg class="{style.text} pointer-events-none fill-current h-6 w-6" role="button"
             xmlns="http://www.w3.org/2000/svg"
             viewBox="0 0 20 20"><title>Close</title>
            <path
                d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z"/>
        </svg>
    </button>
</div>
