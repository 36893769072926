<script>
    import Route from "../Route.svelte";

    let clazz = "";
    export {clazz as class};
    export let to = null;
    export let args = [];
    export let item = null;
    let deleted = (item) ? item.deleted_at : false;
</script>

<td class:line-through={deleted} {...$$restProps} on:click>
    {#if to}
        <Route {to} {args}>
            <div class="px-4 py-3 {clazz}">
                <slot/>
            </div>
        </Route>
    {:else}
        <div class="px-4 py-3 {clazz}">
            <slot/>
        </div>
    {/if}
</td>
