<script>
    import {router} from "@inertiajs/svelte";

    let form;
    function handleSubmit(event){
        submitForm()
    }

    function handleChange(event){
        submitForm()
    }
    function submitForm(){
        let data = new FormData(form)
        // console.log(data)

        let params = {};
        for(let key of data.keys()){
            params[key] = data.get(key)
        }
        // console.log(params)
        router.reload({data: params, replace: true})
    }
</script>
<form on:submit|preventDefault={handleSubmit} on:change={handleChange} bind:this={form}>
    <slot></slot>
</form>
