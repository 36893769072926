<script>
    import PageTitle from "../../Ui/Typography/PageTitle.svelte";
    import FormSection from "../../Ui/Layout/FormSection.svelte";
    import Field from "../../Ui/Form/Field.svelte";
    import PrimaryButton from "../../Ui/Interactive/PrimaryButton.svelte";
    import {useForm} from "@inertiajs/svelte";
    import Route from "../../Ui/Route.svelte";
    import Stack from "../../Ui/Layout/Stack.svelte";

    let form = useForm({
        name: null,
        email: null,
        password: null
    });
    const submit = () => $form.post(route("users.store"));

</script>
<div class="max-w-7xl mx-auto py-10 sm:px-6 lg:px-8">
    <FormSection on:submit={submit}>
        <div slot="title">
            <PageTitle title="Benutzer erstellen"/>
        </div>
        <div slot="description">
            Legen Sie einen neuen Benutzer an um mit anderen zusammen zu arbeiten.
        </div>

        <div slot="form">
            <Stack>
                <Field bind:form name="name" label="Name"/>
                <Field bind:form name="email" label="Email"/>
                <Field bind:form name="password" type="password" label="Password"/>
            </Stack>
        </div>

        <Route to="users.index" slot="back">
            Abbrechen
        </Route>
        <svelte:fragment slot="actions">
            <PrimaryButton type="submit">
                Speichern
            </PrimaryButton>
        </svelte:fragment>

    </FormSection>

</div>
