<script>
    import {permissions, user} from "../../stores/page.js";
    import _ from "lodash";
    import Route from "../Route.svelte";
    import HelpCircle from "../../Icons/HelpCircle.svelte";
    import {page} from "@inertiajs/svelte";

    let toRoute;
    export {toRoute as route};
    export let title;
    export let permission = null;
    export let active = null;
    let clazz = "";
    export {clazz as class};
    active = active ?? toRoute;

    let isActive = false;
    let classes = "";

    function checkIsActive() {
        isActive = route().current(active);
        classes = isActive ? "text-primary " : "";
    }

    page.subscribe(checkIsActive);

    const userHasPermission = permission => $permissions ? $permissions.includes(permission) : false;
    let hasPermission = permission ? userHasPermission(permission) : true;

</script>
{#if hasPermission}
    <button class="w-full relative py-1 border-t {clazz}">
        {#if isActive}
            <span class="absolute inset-y-0 left-0 my-2 w-1 bg-primary-600 rounded-tr-lg rounded-br-lg"
                  aria-hidden="true"></span>
        {/if}
        <Route to={toRoute}
               class="w-full text-sm transition-colors duration-150 hover:text-gray-800 {classes}">
            <span class="">{title}</span>
        </Route>
    </button>
{/if}
