<script context="module">
    import AuthLayout from "../../Layouts/AuthLayout.svelte";

    export const layout = AuthLayout;
</script>
<script>
    import {useForm} from "@inertiajs/svelte";
    import Field from "../../Ui/Form/Field.svelte";
    import Submit from "../../Ui/Form/Submit.svelte";
    import PageTitle from "../../Ui/Typography/PageTitle.svelte";
    import Form from "../../Ui/Form/Form.svelte";
    import Stack from "../../Ui/Layout/Stack.svelte";
    import AppLink from "../../Ui/AppLink.svelte";

    export let status;

    let form = useForm({
        email: ""
    });

    function submit() {
        $form.post(route("password.email"));
    }
</script>

<Stack>
    <PageTitle title="Passwort vergessen"/>

    <div class="mb-4 text-sm text-gray-600">
        Passwort vergessen? Kein Problem. Wir senden Ihnen gerne einen Link zum zurücksetzen zu.
    </div>
    {#if status}
        <div class="mb-4 font-medium text-sm text-green-600">
            {@html status }
        </div>
    {/if}

    <Form on:submit={submit}>
        <Field bind:form name="email" label="E-Mail" type="email" placeholder="jane.doe@example.wien" required
               autofocus/>

        <Submit class="w-full">
            Link anfordern
        </Submit>
    </Form>


    <div class="mt-2">
        <AppLink to="login">
            Zum login
        </AppLink>
    </div>
</Stack>
