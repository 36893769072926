<script>
    import PageTitle from "../../../Ui/Typography/PageTitle.svelte";
    import FormSection from "../../../Ui/Layout/FormSection.svelte";
    import Field from "../../../Ui/Form/Field.svelte";
    import PrimaryButton from "../../../Ui/Interactive/PrimaryButton.svelte";
    import {page, useForm} from "@inertiajs/svelte";
    import Route from "../../../Ui/Route.svelte";
    import Label from "../../../Ui/Form/Label.svelte";
    import {user} from "../../../stores/page.js";
    import Stack from "../../../Ui/Layout/Stack.svelte";

    export let categories;
    export let group;
    let form = useForm({
        category_id: ""+group.category_id,
        name: group.name,
        email: group.email,
        count_estimated: group.count_estimated,
        start_date: group.start_date,
        vip: group.vip,
        is_walk_in: group.is_walk_in,
        booking_code: group.booking_code,
    });

    function update() {
        $form.put(route("disclaimer.group.update", group.id));
    }

</script>

<div class="max-w-7xl mx-auto py-10 sm:px-6 lg:px-8">
    <FormSection on:submit={update}>
            <span slot="title">
                <PageTitle title="Gruppe bearbeiten"/>
            </span>
        <span slot="description">Ändern Sie Gruppe.</span>

        <div slot="form">
            <Stack>
                <Field bind:form name="category_id" label="Kategorie" type="select" options={categories} required/>
                <Field bind:form name="name" label="Name" required/>
                <Field bind:form name="email" label="E-Mail Adresse ansprechpartner" required/>
                <Field bind:form name="start_date" label="Datum & Uhrzeit" type="datetime" showTime={!$form.is_walk_in} required/>
                <Field bind:form name="count_estimated" label="Voraussichtliche Anzahl"/>
                <Field bind:form name="booking_code" label="Buchungsnummer" />
                <Field bind:form name="vip" label="VIP" type="checkbox"/>
                <Field bind:form name="is_walk_in" label="Walk In Gruppe" type="checkbox"/>
            </Stack>
        </div>

        <Route to="disclaimer.group.index" slot="back">
            Abbrechen
        </Route>
        <div slot="actions">
            <PrimaryButton type="submit" disabled={$form.processing}>
                Speichern
            </PrimaryButton>
        </div>

    </FormSection>

</div>
