<script>
    import TableRow from "../../Ui/Table/TableRow.svelte";
    import TableColumn from "../../Ui/Table/TableColumn.svelte";
    import {localDateShort, localTime} from "../../lib/date.js";
    import AlertTriangle from "../../Icons/AlertTriangle.svelte";
    import Modal from "../../Ui/Modal.svelte";
    import Close from "../../Icons/Close.svelte";
    import Form from "../../Ui/Form/Form.svelte";
    import Stack from "../../Ui/Layout/Stack.svelte";
    import PrimaryButton from "../../Ui/Interactive/PrimaryButton.svelte";
    import {router} from "@inertiajs/svelte";
    import ArrowsReload from "../../Icons/ArrowsReload.svelte";
    import Trash from "../../Icons/Trash.svelte";
    import Check from "../../Icons/Check.svelte";
    import Confirm from "../../Ui/Interactive/Confirm.svelte";
    import Print from "../../Icons/Print.svelte";

    export let groups = null;
    export let disclaimers;
    export let id;
    export let open;
    export let first_name;
    export let last_name;
    export let birth_date;
    export let has_signature;
    export let street;
    export let zip;
    export let place;
    export let confirmed;
    export let readonly = false;

    $: age = calcAge(birth_date);
    $: duplicate = disclaimers.filter(d => d.first_name === first_name && d.last_name === last_name).length > 1;

    $: show = open || ((age < 18 || !has_signature || duplicate) && !confirmed);

    $: warningColor = getWarningColor(age, duplicate, has_signature);


    function calcAge(birth_date) {
        let birthday = new Date(birth_date);
        let ageDifMs = Date.now() - birthday.getTime();
        let ageDate = new Date(ageDifMs); // miliseconds from epoch
        return Math.abs(ageDate.getUTCFullYear() - 1970);
    }

    let showSignature = false;
    let showChangeGroup = false;

    let selectedGroup = null;

    function changeGroup() {
        router.post(route("disclaimer.entry.move", id), {
            group: selectedGroup,
        }, {
            preserveScroll: true,
        });
    }

    let showDelete = false
    async function deleteEntry() {
        await router.delete(route("disclaimer.entry.remove", id), {
            preserveScroll: true,
        });
        showDelete = false
    }


    let showConfirm = false;
    async function confirmEntry() {
        await router.put(route("disclaimer.entry.confirm", id), {
            preserveScroll: true,
        });
        showConfirm = false
    }

    function getWarningColor(age, duplicate, has_signature) {
        if (!has_signature) return "text-red-500";
        if (age < 10) return "text-red-500";
        if (age < 18) return "text-orange-500";
        if (duplicate) return "text-yellow-500";
        return "";
    }
</script>

{#if show}
    <TableRow class="{warningColor}">
        <TableColumn colspan="2">

            <span title="{localDateShort(birth_date)}"
                  class="bg-gray-300 px-1 rounded text-black w-10 text-center inline-block">
                {age}J.
            </span>
            <span>
                {first_name} {last_name}
            </span>
            <span class="text-xs text-gray-500">
                {street}, {zip} {place}
            </span>
            {#if confirmed}
                <span title="Manuell bestätigt">
                    <Check/>
                </span>
            {/if}
        </TableColumn>
        <TableColumn class="relative text-center">
            {#if has_signature}
                <button type="button" class="py-1 px-3" on:click={() => showSignature = true}>
                    <img src="{route('disclaimer.entry.signature-thumbnail', id)}"
                         class="h-12 absolute top-0 signature hover:border border-primary rounded"/>
                </button>
            {:else}
                <span class="py-1 px-3" title="Signatur fehlt!">
                    <AlertTriangle/>
                </span>
            {/if}

        </TableColumn>
        <TableColumn>
            <div class="flex gap-2">
                {#if !readonly }
                    {#if groups}
                        <button type="button" on:click={() => showChangeGroup = true} class="text-gray-900 hover:text-blue-500"
                                title="Gruppe wechseln">
                            <ArrowsReload/>
                        </button>
                    {/if}
                    <button type="button" on:click={() => showDelete = true} class="text-gray-900 hover:text-blue-500" title="Löschen">
                        <Trash/>
                    </button>
                    <button type="button" on:click={() => showConfirm = true} class="text-gray-900 hover:text-blue-500" title="Bestätigen">
                        <Check/>
                    </button>
                {/if}
                <a href={route('disclaimer.entry.print', id)} target="_blank" title="PDF Drucken">
                    <Print/>
                </a>
            </div>
        </TableColumn>
    </TableRow>
{/if}

{#if showSignature}
    <Modal bind:show={showSignature} class="z-10" let:hide>
        <div class="bg-primary-100 rounded flex justify-center relative">
            <button on:click={hide} class="absolute w-8 h-8 inline top-0 right-0 bg-white rounded-full p-1 m-1">
                <Close/>
            </button>

            <img src="{route('disclaimer.entry.signature-thumbnail', id)}"/>
        </div>
    </Modal>
{/if}


{#if groups && showChangeGroup}
    <Modal bind:show={showChangeGroup} class="z-10" let:hide>
        <div class="bg-primary-100 rounded flex justify-center relative p-4">
            <button on:click={hide} class="absolute w-8 h-8 inline top-0 right-0 bg-white rounded-full p-1 m-1">
                <Close/>
            </button>

            <Stack>
                <div class="text-lg">
                    Gruppe wechseln für {first_name} {last_name}
                </div>

                <Form on:submit={changeGroup}>
                    <select bind:value={selectedGroup}
                            class="form-select border-gray-300 focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 rounded-md shadow-sm bg-white">
                        {#each groups as group(group.id)}
                            <option value={group.id}>
                                {localTime(group.start_date)} {group.name}
                            </option>
                        {/each}
                    </select>

                    <PrimaryButton type="submit">
                        Verschieben
                    </PrimaryButton>
                </Form>

            </Stack>
        </div>
    </Modal>
{/if}

<Confirm bind:open={showDelete} class="z-10" title="Löschen" confirm="Löschen" type="danger" inModal={true} on:confirm={deleteEntry}>
    <div>
        Haftungsausschluss für {first_name} {last_name} wirklick löschen?
    </div>
</Confirm>

<Confirm bind:open={showConfirm} class="z-10" title="Bestätigen" confirm="Bestätigen" type="success" inModal={true} on:confirm={confirmEntry}>
    <div>
        Haftungsausschluss für {first_name} {last_name} wirklich bestätigen?
    </div>
</Confirm>

<style>
    .signature {
        transform: translateX(-50%);
    }
</style>
