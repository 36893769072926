<script>
    import PrimaryButton from "../../Ui/Interactive/PrimaryButton.svelte";
    import DialogModal from "../../Ui/Interactive/DialogModal.svelte";
    import Field from "../../Ui/Form/Field.svelte";
    import SecondaryButton from "../../Ui/Interactive/SecondaryButton.svelte";
    import ActionSection from "../../Ui/Layout/ActionSection.svelte";
    import {useForm} from "@inertiajs/svelte";

    let form = useForm({
        password: null,
    }, {
        preserveScroll: true,
    })

    export let sessions;

    let confirmingLogout = false;

    function confirmLogout() {
        confirmingLogout = true;
    }

    function abortLogoutOtherBrowserSessions() {
        confirmingLogout = false;
    }

    function logoutOtherBrowserSessions() {
        $form.delete(route('other-browser-sessions.destroy'),{
            onSuccess: () => confirmingLogout = false,
            // onError: () => this.$refs.password.focus(),
            onFinish: () => $form.reset(),

            // onError() {
            //     // Keep modal open after refresh
            //     window.history.state.rememberedState.confirmingLogout = true
            // }
        })
    }

    // Load open Modal state from state history
    setTimeout(() => {
        confirmingLogout = window.history.state.rememberedState.confirmingLogout || false
    }, 0)

</script>


<ActionSection>
    <slot slot="title">Browser Sessions</slot>
    <slot slot="description">Falls gewünscht können Sie sich hier ihren Sessions auf anderen Geräten abmelden.</slot>

    <slot slot="content">

        <!-- Other Browser Sessions -->
        {#if sessions.length > 0}
            <div class="mt-5 space-y-6">
                {#each sessions as session,i}
                    <div class="flex items-center">
                        <div>
                            {#if session.agent.is_desktop}
                                <svg fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                     viewBox="0 0 24 24" stroke="currentColor" class="w-8 h-8 text-gray-500">
                                    <path
                                        d="M9.75 17L9 20l-1 1h8l-1-1-.75-3M3 13h18M5 17h14a2 2 0 002-2V5a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"></path>
                                </svg>
                            {:else }
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" stroke-width="2"
                                     stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"
                                     class="w-8 h-8 text-gray-500">
                                    <path d="M0 0h24v24H0z" stroke="none"></path>
                                    <rect x="7" y="4" width="10" height="16" rx="1"></rect>
                                    <path d="M11 5h2M12 17v.01"></path>
                                </svg>
                            {/if}
                        </div>

                        <div class="ml-3">
                            <div class="text-sm text-gray-600">
                                {session.agent.platform} - {session.agent.browser}
                            </div>

                            <div>
                                <div class="text-xs text-gray-500">
                                    {session.ip_address},

                                    {#if session.is_current_device}
                                        <span class="text-green-500 font-semibold">Dieses Gerät</span>
                                    {/if}
                                    <span v-else>zuletzt aktiv {session.last_active}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                {/each}
            </div>
        {/if}

        <div class="flex items-center mt-5">
            <PrimaryButton on:click={confirmLogout}>
                Auf anderen Geräten abmelden
            </PrimaryButton>
        </div>
    </slot>


</ActionSection>


<DialogModal bind:show={confirmingLogout}>
    <slot slot="title">Auf anderen Geräten abmelden</slot>
    <div slot="content">

        Bitte geben Sie Ihr Passwort ein um sich bei allen Geräten abzumelden.

        <Field bind:form name="password" type="password" label="Passwort"/>
    </div>
    <slot slot="footer">
        <SecondaryButton on:click={abortLogoutOtherBrowserSessions}>
            Abbrechen
        </SecondaryButton>
        <PrimaryButton on:click={logoutOtherBrowserSessions}>
            Auf anderen Geräten abmelden
        </PrimaryButton>
    </slot>

</DialogModal>
