<thead>
    <tr class="text-xs font-semibold tracking-wide text-left text-gray-500 uppercase border-b  bg-gray-50  ">
        <slot/>
    </tr>
</thead>
<style>
    tr :global(th) {
        @apply px-4 py-3;
    }
</style>
