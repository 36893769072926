<script>
    import {router} from "@inertiajs/svelte";
    import RemoveCircle from "../../Icons/RemoveCircle.svelte";

    export let search;
    export let name = 'q'

    function resetSearch() {
        search = null;
        router.reload({data: {q:search}, replace: true})
    }

</script>
<div class="flex flex-1 justify-items-end">
    <div class="relative w-full max-w-xl focus-within:text-primary-500">
        <div class="absolute inset-y-0 flex items-center pl-2">
            <svg class="w-4 h-4" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20">
                <path fill-rule="evenodd"
                      d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                      clip-rule="evenodd"></path>
            </svg>
        </div>
        {#if search}
            <button type="button" class="absolute inset-y-0 flex items-center pr-2 right-0 cursor-pointer"
                 on:click|preventDefault={resetSearch}>
                <RemoveCircle/>
            </button>
        {/if}
        <input
            class="w-full pl-8 pr-8 text-sm text-gray-700 placeholder-gray-600 bg-gray-100 border-0 rounded-md  :shadow-outline-gray :placeholder-gray-600   focus:placeholder-gray-500 focus:bg-white focus:border-primary-300 focus:outline-none focus:shadow-outline-primary form-input"
            type="text" placeholder="Suche" aria-label="Search" {name} bind:value={search}>

    </div>
</div>
