<script>
    import Modal from "../Modal.svelte";

    export let show = false;
</script>

<Modal bind:show>
    <form>
        <div>
            <form on:submit|preventDefault>
                <div class="shadow overflow-hidden sm:rounded-md">

                    <div class="flex px-4 py-3 bg-gray-50  text-right sm:px-6 text-lg">
                        <slot name="title"/>
                    </div>

                    <div class="px-4 py-5 bg-white  sm:p-6">

                        <div class="">
                            <slot name="content"/>
                        </div>

                    </div>

                    <div class="flex items-center justify-end px-4 py-3 bg-gray-50  text-right sm:px-6 gap-4">
                        <slot name="footer"/>
                    </div>
                </div>
            </form>
        </div>

    </form>

</Modal>
