<script lang="ts">
    import {inertia} from '@inertiajs/svelte'

    export let to;
    export let args = [];
    export let href;
    export let target = null;
    href = href ?? route(to, args)
</script>
{#if target}
    <a {href} {target} {...$$restProps}>
        <slot/>
    </a>
{:else}
    <a use:inertia {href} {...$$restProps}>
        <slot/>
    </a>
{/if}
