<script>

    import Logo from "../Ui/Logo.svelte";
    import {pageTitle, settings} from "../stores/page.js";
    import Route from "../Ui/Route.svelte";

    $: siteName = $settings.general.site_name ?? "";

    $: title = $pageTitle ? `${$pageTitle} | ${siteName}` : siteName;
    export let category;
    export let categories;
</script>
<svelte:head>
    <title>{title}</title>
</svelte:head>
{#if category}
    <div class="flex flex-col-reverse gap-2 lg:flex-row bg-primary p-4">
        <ul class="group-nav flex-1 text-white flex justify-center lg:justify-end items-center gap-4 ">
            {#if categories}
                {#each categories as c}
                    <li>
                        <Route to="frontend.disclaimerGroups.select" args={c.slug}
                               class=" px-4 py-2 text-lg {c.slug == category.slug ? 'active' : ''}">
                            {c.name}
                        </Route>
                    </li>

                {/each}
            {/if}
        </ul>
        <nav>
            <Route class="flex justify-center" href="/">
                <img src={category.image_url} alt="Logo" class="h-20"/>
            </Route>
        </nav>
        <div class="flex-1">

        </div>
    </div>

{/if}
<div class="flex flex-col gap-4 min-h-screen bg-white w-full">

    <div class="flex flex-col gap-4 p-6 flex-1">

        <slot/>
    </div>

    <footer class="text-xs p-4 bg-primary text-center flex flex-col lg:flex-row gap-1 justify-center">
        <div>PAP Event GmbH</div> |
        <div>Tel: +43 50 7270-0</div> |
        <div>Mail: office@pap-event.at</div> |
        <div>Firmensitz: Hauptstrasse 39; A-3721 Limberg</div>
    </footer>
</div>


<style>
    .group-nav :global(a) {
        z-index: 1;
        position: relative;
    }

    .group-nav :global(a.active)::before {
        background-image: url(data:image/svg+xml;charset=UTF-8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2210%22%20height%3D%2210%22%20viewBox%3D%220%200%2010%2010%22%3E%0A%20%20%20%20%3Cpolygon%20fill%3D%22%23959c91%22%20points%3D%220%209%205.03%204.35%2010%208.98%2010%205.62%205.03%201%200%205.63%200%209%22%2F%3E%0A%3C%2Fsvg%3E%0A);
        content: "";
        display: block;
        position: absolute;
        z-index: -1;
        bottom: -5px;
        height: 10px;
        background-color: transparent;
        transition: .1s ease-in-out;
        transition-property: background-color, border-color, box-shadow, height, left, right, opacity;
        --uk-navbar-nav-item-line-margin-horizontal: 0;
        left: var(--uk-navbar-nav-item-line-margin-horizontal);
        right: var(--uk-navbar-nav-item-line-margin-horizontal);
        background-color: transparent !important;
        background-size: auto
    }

    .group-nav {
        margin-left: auto;
        margin-right: auto;
    }


</style>
