<script>
    import PageTitle from "../../../Ui/Typography/PageTitle.svelte";
    import FormSection from "../../../Ui/Layout/FormSection.svelte";
    import Field from "../../../Ui/Form/Field.svelte";
    import PrimaryButton from "../../../Ui/Interactive/PrimaryButton.svelte";
    import {page, useForm} from "@inertiajs/svelte";
    import Route from "../../../Ui/Route.svelte";
    import Label from "../../../Ui/Form/Label.svelte";
    import {user} from "../../../stores/page.js";
    import Stack from "../../../Ui/Layout/Stack.svelte";
    import Editor from "../../../Components/Text/Editor.svelte";

    export let textblock;
    let form = useForm({
        name: textblock.name,
        editor: textblock.editor,
        content: textblock.contant
    });

    function update() {
        $form.put(route("text.textblock.update", textblock.id));
    }
    // console.log(textblock.editor)
</script>

<div class="max-w-7xl mx-auto py-10 sm:px-6 lg:px-8">
    <FormSection on:submit={update}>
            <span slot="title">
                <PageTitle title="Textblock bearbeiten"/>
            </span>
        <span slot="description">Text ändern.</span>

        <div slot="form">
            <Stack>
                <Label>
                    Schlüssel
                    <div class="bg-gray-200 p-1 rounded">
                        {textblock.key}
                    </div>
                </Label>
                <Field bind:form name="name" label="Name"/>

                <Label>Text</Label>
                <Editor bind:data={$form.editor} bind:content={$form.content} >

                </Editor>
            </Stack>
        </div>

        <Route to="text.textblock.index" slot="back">
            Abbrechen
        </Route>
        <div slot="actions">
            <PrimaryButton type="submit" disabled={$form.processing}>
                Speichern
            </PrimaryButton>
        </div>

    </FormSection>

</div>
