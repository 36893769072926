<script>
    import {permissions, user} from "../../stores/page.js";
    import _ from "lodash";
    import Route from "../Route.svelte";
    import HelpCircle from "../../Icons/HelpCircle.svelte";
    import {page} from "@inertiajs/svelte";

    let toRoute;
    export {toRoute as route};
    export let title;
    export let permission = null;
    export let active = null;
    export let icon = null;
    let clazz = "";
    export {clazz as class};
    active = active ?? toRoute;

    let isActive = false;
    let classes = "";

    function checkIsActive() {
        isActive = route().current(active);
        classes = isActive ? "text-primary " : "";
    }

    page.subscribe(checkIsActive);

    const userHasPermission = permission => $permissions ? $permissions.includes(permission) : false;
    let hasPermission = permission ? userHasPermission(permission) : true;

    // let classes = 'inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium leading-5 focus:outline-none transition duration-150 ease-in-out whitespace-nowrap ' + (isActive
    //         ? 'border-primary-400  text-gray-900  :text-gray-100 focus:border-indigo-700 '
    //         : 'border-transparent  text-gray-500  hover:text-gray-700 :text-gray-100 hover:border-gray-100  focus:text-gray-700 focus:border-gray-300 ');
</script>
{#if hasPermission}
    <!--<Route class={classes} to={toRoute}>-->
    <!--    {title}-->
    <!--</Route>-->

    <button class="relative px-6 pb-4 pt-5  border-t w-full {clazz}">
        {#if isActive}
            <span class="absolute inset-y-0 left-0 my-3 w-1 bg-primary-600 rounded-tr-lg rounded-br-lg"
                  aria-hidden="true"></span>
        {/if}
        <Route to={toRoute}
               class="inline-flex flex-col gap-2 justify-center items-center w-full text-sm transition-colors duration-150 hover:text-gray-800 {classes}">
            {#if icon}
                <svelte:component this="{icon}" mono={!isActive} class="w-6 h-6"/>
            {:else}
                <HelpCircle class="w-6 h-6"/>
            {/if}
            <span class="">{title}</span>
        </Route>
    </button>
    <slot isActive={isActive}></slot>
{/if}
