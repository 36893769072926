<script context="module">
    import GuestLayout from "../../../Layouts/GuestLayout.svelte";

    export const layout = GuestLayout;
</script>
<script>
    import H2 from "../../../Ui/Typography/H2.svelte";
    import Route from "../../../Ui/Route.svelte";
    import PageTitle from "../../../Ui/Typography/PageTitle.svelte";

    export let categories;
    export let text_title;
    export let text_top;
</script>

<div class="text-center">
    <PageTitle title={text_title}/>
    {@html text_top}
</div>


<div class="flex flex-col flex-1 gap-8 pb-20 items-center justify-center">

    {#each categories as category}
        <Route to="frontend.disclaimerGroups.select" args={category.slug}
               class="border rounded-md bg-primary text-white px-4 py-2 text-2xl hover:bg-primary-700 text-center w-full md:w-auto justify-center items-center flex flex-col ">
            {#if category.image_url}
                <img src={category.image_url} class="w-48 m-2"/>
            {/if}
            {category.name}
        </Route>
    {/each}
</div>
