<script context="module">
    import AuthLayout from "../../Layouts/AuthLayout.svelte";

    export const layout = AuthLayout;
</script>
<script>
    import {useForm} from "@inertiajs/svelte";
    import CenteredCard from "../../Ui/Cards/CenteredCard.svelte";
    import Field from "../../Ui/Form/Field.svelte";
    import Submit from "../../Ui/Form/Submit.svelte";
    import H2 from "../../Ui/Typography/H2.svelte";
    import AppLink from "../../Ui/AppLink.svelte";

    export let email;
    export let token;

    let form = useForm({
        email,
        token,
        password: null,
        password_confirmation: null
    });

    function submit() {
        $form.post(route("password.update"), {
            onFinish: () => $form.reset("password", "password_confirmation"),
        });
    }
</script>

<CenteredCard>
    <div class="flex flex-col overflow-y-auto md:flex-row">
        <div class="h-32 md:h-auto md:w-1/2">
            <img aria-hidden="true" class="object-cover w-full h-full" src="/img/login-office.jpeg"
                 alt="Office">
        </div>
        <div class="flex items-center justify-center p-6 sm:p-12 md:w-1/2">
            <div class="w-full">
                <H2>Neues Passwort setzen</H2>

                {#if status}
                    <div class="mb-4 font-medium text-sm text-green-600">
                        {@html status }
                    </div>
                {/if}

                <form on:submit|preventDefault={submit}>
                    <Field bind:form name="email" label="E-Mail" type="email" placeholder="jane.doe@example.wien" required autofocus/>
<!--                    <Field bind:form name="token" label="Token" type="text" placeholder="" required autofocus/>-->

                    <Field bind:form name="password" label="Passwort" type="password"
                           placeholder="&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;" required/>
                    <Field bind:form name="password_confirmation" label="Passwort wiederholen" type="password"
                           placeholder="&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;" required/>


                    <Submit class="w-full">
                        Passwort setzen
                    </Submit>
                </form>


                <div class="mt-4">
                    <AppLink to="login">
                        Zum login
                    </AppLink>
                </div>
            </div>
        </div>

    </div>




</CenteredCard>
