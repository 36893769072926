<script>
    import ListSection from "../../Ui/Layout/ListSection.svelte";
    import FilterSelect from "../../Ui/Interactive/FilterSelect.svelte";
    import SearchBar from "../../Ui/Interactive/SearchBar.svelte";
    import Container from "../../Ui/Layout/Container.svelte";
    import TableHeader from "../../Ui/Table/TableHeader.svelte";
    import SortableLink from "../../Ui/Interactive/SortableLink.svelte";
    import TableAction from "../../Ui/Table/TableAction.svelte";
    import PrimaryButton from "../../Ui/Interactive/PrimaryButton.svelte";
    import Print from "../../Icons/Print.svelte";
    import Key from "../../Icons/Key.svelte";
    import TableRow from "../../Ui/Table/TableRow.svelte";
    import TableColumn from "../../Ui/Table/TableColumn.svelte";
    import PageTitle from "../../Ui/Typography/PageTitle.svelte";
    import Stack from "../../Ui/Layout/Stack.svelte";
    import Pencil from "../../Icons/Pencil.svelte";

    export let users;
    export let roleOptions;
</script>
<Container>
    <ListSection list={users}>
        <div slot="title">
            <PageTitle title="Benutzer"/>
        </div>
        <div slot="description">Benutzerverwaltung</div>

        <div slot="actions">
            <Stack>
                <PrimaryButton route="users.create" class="">
                    Neuen Benutzer erstellen
                </PrimaryButton>
                <SearchBar/>
            </Stack>
        </div>

        <slot slot="filter">
            <div>
                <FilterSelect
                    name="roles"
                    emptyOption="Alle Rollen"
                    options="{roleOptions}"
                    multi="{true}"
                />
            </div>
        </slot>


        <TableHeader slot="header">
            <th>
                <SortableLink column="name">Name</SortableLink>
            </th>
            <th>
                <SortableLink column="email">Email</SortableLink>
            </th>
            <th class="w-20"></th>
        </TableHeader>

        <TableRow slot="item" let:item>
            <TableColumn>{item.name}</TableColumn>
            <TableColumn>{item.email}</TableColumn>
            <TableColumn class="flex flex-row gap-2 items-center">
                <TableAction  to="users.edit" args={item.id} icon={Pencil}/>
                <TableAction to="impersonate" args={item.id} icon={Key}/>

            </TableColumn>
        </TableRow>


    </ListSection>

</Container>
