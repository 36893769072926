<script context="module">
    import AuthLayout from "../../Layouts/AuthLayout.svelte";

    export const layout = AuthLayout;
</script>
<script>
    import {useForm} from "@inertiajs/svelte";
    import Field from "../../Ui/Form/Field.svelte";
    import Submit from "../../Ui/Form/Submit.svelte";
    import Form from "../../Ui/Form/Form.svelte";
    import PageTitle from "../../Ui/Typography/PageTitle.svelte";
    import Stack from "../../Ui/Layout/Stack.svelte";
    import AppLink from "../../Ui/AppLink.svelte";

    export let status;
    let form = useForm({
        password: "",
    });

    function submit() {
        $form.post(route("password.confirm"), {
            onFinish: () => $form.reset(),
        });
    }
</script>
<Stack>
    <PageTitle title="Passwortabfrage"/>
    <div class="mb-4 text-sm text-gray-600">
        Dies ist ein gesicherter Bereich, bitte bestätige dein Passwort um fortzufahren.
    </div>

    {#if status}
        <div class="mb-4 font-medium text-sm text-green-600">
            {@html status }
        </div>
    {/if}

    <Form on:submit={submit}>

        <Field bind:form name="password" label="Passwort" type="password"
               placeholder="&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;"
               required/>
        placeholder="&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;"
        required/>


        <Submit class="w-full">
            Bestätigen
        </Submit>
    </Form>


    <div class="mt-4">
        <AppLink to="login">
            Zum Login
        </AppLink>
    </div>
</Stack>
