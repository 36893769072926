<script>
    import FormSection from "../../Ui/Layout/FormSection.svelte";
    import Field from "../../Ui/Form/Field.svelte";
    import PrimaryButton from "../../Ui/Interactive/PrimaryButton.svelte";
    import {useForm} from "@inertiajs/svelte";

    export let user;
    let form = useForm({
        name: user.name,
        email: user.email
    })
    const submit = () => $form.put(route('user-profile-information.update'), {
        errorBag: 'updateProfileInformation'
    })
</script>

<FormSection on:submit={submit}>
    <slot slot="title">Profil</slot>
    <slot slot="description">Profilinformationen und E-Mail Adresse anpassen.</slot>


    <slot slot="form">
        <Field bind:form name="name" label="Name"/>

        <Field bind:form name="email" type="email" label="E-Mail Adresse"/>
    </slot>

    <slot slot="actions">
       <PrimaryButton disabled={$form.processing} type="submit">
           Speichern
       </PrimaryButton>
    </slot>

</FormSection>
