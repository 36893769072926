<script>

    import PageTitle from "../../../Ui/Typography/PageTitle.svelte";
    import Container from "../../../Ui/Layout/Container.svelte";
    import DisclaimerRow from "../../../Components/Dashboard/DisclaimerRow.svelte";
    import Table from "../../../Ui/Table/Table.svelte";
    import {localDateShort, localTime} from "../../../lib/date.js";
    import Stack from "../../../Ui/Layout/Stack.svelte";
    import SearchBar from "../../../Ui/Interactive/SearchBar.svelte";

    export let group;
    export let disclaimers;

    let query = ''

    $: filteredDisclaimers = filterDisclaimers(disclaimers, query);

    function filterDisclaimers(disclaimers, query) {
        if (!query) return disclaimers;
        return disclaimers.filter(disclaimer => {
            let name = disclaimer.first_name + " " + disclaimer.last_name;
            return name.toLowerCase().includes(query.toLowerCase());
        });
    }
    name = `${group.is_walk_in ? " Walk In: " : ""}${group.vip ? "VIP: " : ""} ${group.name} ${group.vip ? group.public_name : ""}`;
</script>
<Container>
    <Stack>
        <div class="flex items-center">
            <PageTitle title="Gruppe {name}"/>

            <div class="ml-auto text-xl">
                {disclaimers.length} /
                {#if group.count_estimated}
                    {group.count_estimated}
                {:else}
                    ∞
                {/if}
            </div>
        </div>
        <div>
            {localDateShort(group.start_date)} {localTime(group.start_date)}
        </div>

        <div>
            <SearchBar bind:search={query} placeholder="Suche nach Name"/>
        </div>
        <Table class="w-full">
            {#each filteredDisclaimers as disclaimer(disclaimer.id)}
                <DisclaimerRow {...disclaimer} {disclaimers} open={true} readonly={true}/>
            {/each}

        </Table>
    </Stack>

</Container>
