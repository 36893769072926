<script>
    import Route from "../Route.svelte";

    export let route = '';
    export let as = 'link';
</script>
<div>
    {#if as === 'button'}
    <button type="submit" class="block w-full px-4 py-2 text-sm leading-5 text-gray-700  text-left hover:bg-gray-100 :bg-gray-800 focus:outline-none focus:bg-gray-100 transition duration-150 ease-in-out">
        <slot/>
    </button>
    {:else if as === 'custom'}
    <div class="block px-4 py-2 text-sm leading-5 text-gray-700  hover:bg-gray-100 :bg-gray-800 focus:outline-none focus:bg-gray-100 transition duration-150 ease-in-out">
        <slot/>
    </div>
    {:else }

    <Route to={route} class="block px-4 py-2 text-sm leading-5 text-gray-700  hover:bg-gray-100 :bg-gray-800 focus:outline-none focus:bg-gray-100 transition duration-150 ease-in-out">
        <slot/>
    </Route>
    {/if}
</div>
