<script>
    import MainNavLink from "../../Ui/Navigation/MainNavLink.svelte";
    import Logo from "../../Ui/Logo.svelte";
    import MultipleUsers from "../../Icons/MultipleUsers.svelte";
    import SecondaryNavLink from "../../Ui/Navigation/SecondaryNavLink.svelte";
    import LaptopHome from "../../Icons/LaptopHome.svelte";
    import Ball from "../../Icons/Ball.svelte";
    import TextFormatting from "../../Icons/TextFormatting.svelte";
    import ArrowDown from "../../Icons/ArrowDown.svelte";
    import Envelope from "../../Icons/Envelope.svelte";


    let routes = [
        {
            title: "Dashboard",
            route: "dashboard",
            icon: LaptopHome
        },
        {
            title: "Gruppen",
            route: "disclaimer.group.index",
            active: "disclaimer.group.*",
            permission: 'see sensitive data',
            icon: MultipleUsers,
        },
        {
            title: "Kategorien",
            route: "disclaimer.category.index",
            active: "disclaimer.category.*",
            permission: "manage categories",
            icon: Ball,
        },
        {
            title: "Newsletter",
            route: "marketing.newsletter-export",
            active: "marketing.newsletter-export.*",
            permission: "export emails",
            icon: Envelope,
        },
        {
            title: "Texte",
            route: "text.textblock.index",
            active: "text.textblock.*",
            permission: "manage texts",
            icon: TextFormatting,
        },
        {
            title: "Benutzer",
            route: "users.index",
            active: "users.*",
            permission: "manage users",
            icon: MultipleUsers,
            items: [
                {
                    title: "Benutzer verwalten",
                    route: "users.index",
                    permission: "manage users",
                },
                {
                    title: "Benutzer anlegen",
                    route: "users.create",
                    permission: "manage users",
                },
            ]

        },

    ];

    export let open = false;
    $: openClasses = open ? 'open ' : '';

</script>
<aside class="z-20 w-40 overflow-y-auto bg-white md:block flex-shrink-0 md:shadow transition-transform fixed md:relative -ml-64 md:ml-0 inset-y-0 mt-17 md:mt-0 transform" class:translate-x-64={open}>
    <div>
        <div class="text-lg text-gray-800  items-center pb-4 flex items-center justify-center py-4">
            <Logo/>
        </div>
    </div>
    <div class="text-gray-500 fixed">
        <nav class="border-b">
            {#each routes as route}
                <MainNavLink {...route} let:isActive on:click={()=>open=false}>
                {#if route.items && isActive}
                    <div class="subroutes">
                        {#each route.items as item}
                            <SecondaryNavLink {...item} on:click={()=>open=false}/>
                        {/each}
                    </div>
                {/if}
                </MainNavLink>
            {/each}
        </nav>
    </div>
</aside>
