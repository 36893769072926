<script>
    import {DialogOverlay, DialogContent} from "svelte-accessible-dialog";
    import PrimaryButton from "../../Ui/Interactive/PrimaryButton.svelte";

    export let isOpen;
    export let cancel;
    export let confirm;

    function doCancel(){
        if(cancel){
            cancel()
        }else{
            isOpen = false;
        }
    }
</script>


<DialogOverlay {isOpen} onDismiss={doCancel}>
    <DialogContent aria-label="Definition">
        <div>
            <slot/>
        </div>
        <div class="flex mt-12">
            <button on:click={doCancel} class="underline">
                Abbrechen
            </button>
            <PrimaryButton class="ml-auto" on:click={confirm}>
                OK
            </PrimaryButton>
        </div>

    </DialogContent>
</DialogOverlay>
