<script>
    export let value = "";
    export let type = "text"
    let clazz;
    export { clazz as class };

    let inputProps = $$restProps;
    inputProps.type = type;

</script>

{#if type === "file"}
    <input class="border-gray-300 focus:border-primary-600 focus:ring focus:ring-primary-400 rounded-md bg-white   text-gray-900  placeholder-gray-400 {clazz || ''}" {...$$restProps} type="file" bind:files={value} on:input on:change/>
{:else}
<input class="border-gray-300 focus:border-primary-600 focus:ring focus:ring-primary-400 rounded-md bg-white   text-gray-900  placeholder-gray-400 {clazz || ''}" {...inputProps} bind:value on:input on:change/>
{/if}
