<script>
    import PageTitle from "../../Ui/Typography/PageTitle.svelte";
    import FormSection from "../../Ui/Layout/FormSection.svelte";
    import Field from "../../Ui/Form/Field.svelte";
    import PrimaryButton from "../../Ui/Interactive/PrimaryButton.svelte";
    import SecondaryButton from "../../Ui/Interactive/SecondaryButton.svelte";
    import DangerButton from "../../Ui/Interactive/DangerButton.svelte";
    import {page, useForm} from "@inertiajs/svelte";
    import Route from "../../Ui/Route.svelte";
    import Label from "../../Ui/Form/Label.svelte";
    import {user} from "../../stores/page.js";
    import ConfirmDialog from "../../Components/Layout/ConfirmDialog.svelte";
    import Stack from "../../Ui/Layout/Stack.svelte";

    let userModel;
    export {userModel as user};
    let dialogOpen = false;
    let form = useForm({
        id: userModel.id,
        name: userModel.name,
        password: null,
        email: userModel.email,
        roles: userModel.roles.filter(r => r.active).map(r => r.id)
    });

    function updateUser() {
        $form.put(route("users.update", userModel.id));
    }

    function deleteUser() {
        dialogOpen = true;
    }

    function submitDeleteUser() {
        $form.delete(route("users.destroy", userModel.id));
    }

    function restoreUser() {
        $form.put(route("users.restore", userModel.id));
    }

</script>

<ConfirmDialog bind:isOpen={dialogOpen} confirm={submitDeleteUser}>
    Benutzer wirklich löschen?
</ConfirmDialog>

<div class="max-w-7xl mx-auto py-10 sm:px-6 lg:px-8">
    <FormSection on:submit={updateUser}>
            <span slot="title">
                <PageTitle title="Benutzer bearbeiten"/>
            </span>
        <span slot="description">Ändern Sie Nutzerdaten und setzen Sie ein neues Passwort.</span>

        <div slot="form">
            <Stack>
                <Field bind:form name="name" label="Name"/>
                <Field bind:form name="email" label="Email"/>
                <Field bind:form name="password" type="password" label="Password"/>

                <div class="col-span-6 sm:col-span-4">
                    <h3 class="text-lg text-gray-700 ">Rollen</h3>
                    {#each userModel.roles as role}
                        <Label>
                            <input type="checkbox" value={role.id} bind:group={$form.roles}
                                   class="rounded border-gray-300  text-primary-600 shadow-sm focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white "/>
                            {role.name}
                        </Label>
                    {/each}
                </div>
            </Stack>
        </div>

        <Route to="users.index" slot="back">
            Abbrechen
        </Route>
        <div slot="actions">
            {#if userModel.deleted}
                <SecondaryButton on:click={restoreUser}>
                    Wiederherstellen
                </SecondaryButton>

            {:else if userModel.id !== $user.id}
                <DangerButton on:click={deleteUser} disabled={$form.processing}>
                    Löschen
                </DangerButton>

                <PrimaryButton type="submit" disabled={$form.processing}>
                    Speichern
                </PrimaryButton>
            {/if}

        </div>

    </FormSection>

</div>
