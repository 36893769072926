<script>
    import SectionTitle from "../Typography/SectionTitle.svelte";
    import Table from "../Table/Table.svelte";
    import SimplePagination from "../Pagination/SimplePagination.svelte";
    import TableFilter from "../Table/TableFilter.svelte";

    export let list;
    export let emptyMessage = "Keine Ergebnisse gefunden";
    let items;
    let pagination = null;
    let clazz = "mt-4"
    export {clazz as class}
    $: if (list) {
        if (list.current_page) { // Its a pagniated list
            items = list.data;
            pagination = list;
        } else {
            items = list;
        }
    }
</script>
<div {...$$restProps} class="{clazz}">
    <TableFilter>
        <div class="mb-4">
            <SectionTitle>
                <slot name="title" slot="title"/>
                <slot name="description" slot="description"/>
                <slot name="actions" slot="actions"/>
            </SectionTitle>
        </div>

        <div class="mb-2 md:flex md:flex-wrap">
            <slot name="filter"/>
        </div>
    </TableFilter>
    <Table>
        <slot name="header" slot="header"/>

        {#if items && items.length > 0}
            {#each items as item (item.id?item.id:items.indexOf(item))}
                <slot name="item" item={item}/>
            {/each}
        {/if}
        <div slot="footer">
            {#if !items || items.length < 1}
                <div class="text-center px-2 py-4 bg-white  ">
                    {emptyMessage}
                </div>
            {/if}
            {#if pagination && items && items.length > 0 }
                <SimplePagination pagination={pagination}/>
            {/if}
        </div>
    </Table>
</div>
