<script context="module">
    import GuestLayout from "../../../Layouts/GuestLayout.svelte";

    export const layout = GuestLayout;
</script>
<script>

    import PageTitle from "../../../Ui/Typography/PageTitle.svelte";
    import Route from "../../../Ui/Route.svelte";
    import PrimaryButton from "../../../Ui/Interactive/PrimaryButton.svelte";

    export let groups;
    export let category;
    export let text_top;
</script>

<div class="text-center">
    <PageTitle title={category.name + " Gruppen"}/>

    <p>
        {@html text_top}
    </p>
</div>
<div>

    <div class="flex flex-col gap-2 max-w-xl text-center mx-auto lg:grid lg:grid-cols-2">

        {#each groups as group}
            <PrimaryButton route="frontend.disclaimer.create" args={group.ref} class="py-4">
                <div class="text-lg">{group.name}</div>
            </PrimaryButton>
        {/each}

    </div>

</div>
