<script>

</script>

<form on:submit|preventDefault {...$$restProps}>
    <slot/>
</form>
<style>
    form {
        display: contents;
    }
</style>
