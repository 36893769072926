<script>
    import SectionTitle from "../Typography/SectionTitle.svelte";
    import Stack from "./Stack.svelte";
    //
    // export let form
    export let full = false;
</script>
<div class={full ? 'w-full flex flex-col gap-4' : 'md:grid md:grid-cols-4 md:gap-6'}>
    <SectionTitle>
        <slot slot="title" name="title"/>
        <slot slot="description" name="description"/>
    </SectionTitle>


    <div class="mt-5 md:mt-0 md:col-span-3">
        <form on:submit|preventDefault>
            <div class="shadow overflow-hidden sm:rounded-md">
                <div class="px-4 py-5 bg-white  sm:p-6">
                    <div class="">
                        <slot name="form"/>
                    </div>
                </div>

                <div class="flex items-center  px-4 py-3 bg-gray-50  text-right sm:px-6">
                    <slot name="back"/>
                    <div class="flex-grow"></div>
                    <slot name="actions"/>
                </div>
            </div>
        </form>
    </div>
</div>
