<script>
    import FormSection from "../../Ui/Layout/FormSection.svelte";
    import Field from "../../Ui/Form/Field.svelte";
    import PrimaryButton from "../../Ui/Interactive/PrimaryButton.svelte";
    import {page, useForm} from "@inertiajs/svelte";

    let form = useForm({
        current_password: '',
        password: '',
        password_confirmation: '',
    })
    let submit = () => $form.put(route('user-password.update'), {
        errorBag: 'updatePassword',
    })

</script>

<FormSection on:submit={submit}>
    <slot slot="title">Passwort ändern</slot>
    <slot slot="description">Nutzen Sie ein langes Passwort.</slot>


    <slot slot="form">
        <Field bind:form name="current_password" label="Aktuelles Passwort" type="password"/>

        <Field bind:form name="password" label="Neues Passwort" type="password"/>

        <Field bind:form name="password_confirmation" label="Neues Passwort wiederholen" type="password"/>

    </slot>

    <slot slot="actions">
       <PrimaryButton disabled={$form.processing} type="submit">
           Speichern
       </PrimaryButton>
    </slot>

</FormSection>
