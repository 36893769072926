export function getUrlParams(name){
    if(!name) return []
    const urlSearchParams = new URLSearchParams(window.location.search);

    let values = []
    for (let param of urlSearchParams.entries()){
        if(param[0] === name) {
            values.push(param[1])
        }
    }
    return values
}

export function getUrlParam(name){
    const urlSearchParams = new URLSearchParams(window.location.search);
    for (let param of urlSearchParams.entries()){
        if(param[0] === name) {
            return param[1]
        }
    }
    return null
}
