<script>
    import {createEventDispatcher, onMount} from "svelte";
    import SignaturePad from "signature_pad";

    let canvas
    export let value

    const dispatch = createEventDispatcher()

    onMount(() => {

        const signaturePad = new SignaturePad(canvas);

        const updateValue = (e) => {
            value = signaturePad.isEmpty() ? null : signaturePad.toDataURL();
            dispatch('input')
        }

        signaturePad.addEventListener("afterUpdateStroke", defer(updateValue, 500))

        signaturePad.clear()
        signaturePad.on()

        return () => {
            signaturePad.off();
        }
    })


    let timeout = null;
    function defer(cb, ms){
        return function(arg) {
            if (timeout) {
                clearTimeout(timeout)
            }
            timeout = setTimeout(() => cb(arg), ms)
        }
    }


</script>
<div class="flex items-center justify-center">
<canvas bind:this={canvas} class="border border-primary rounded"></canvas>
</div>
