import { createInertiaApp } from '@inertiajs/svelte'

import "../css/app.css";
import "./bootstrap.js";
import AppLayout from "./Layouts/AppLayout.svelte";

createInertiaApp({
    id: 'app',
    resolve: name => {
        const pages = import.meta.glob('./Pages/**/*.svelte', { eager: true })
        let page = pages[`./Pages/${name}.svelte`]
        return { default: page.default, layout: page.layout || AppLayout }
    },
    setup({ el, App, props }) {
        new App({ target: el, props })
    },
})
