<script>
    import Route from "../Route.svelte";

    export let to = null;
    export let args = [];
    export let icon;

    let clazz = "hover:text-primary-600 p-0.25 "
</script>
{#if to}
    <Route {to} {args} class={clazz} {...$$restProps}>
        <svelte:component this={icon} class="w-4 h-4"/>
    </Route>
{:else}
    <button on:click class={clazz} {...$$restProps}>
        <svelte:component this={icon}/>
    </button>

{/if}
