<script>
    import Label from "./Label.svelte";
    import Input from "./Input.svelte";
    import Checkbox from "./Checkbox.svelte";
    import InputError from "./InputError.svelte";
    import Select from "./Select.svelte";
    import DateTimeInput from "./DateTimeInput.svelte";
    import BirthDateInput from "./BirthDateInput.svelte";

    export let form;
    export let name;

    export let required = false;
    export let value = true;

    export let label = null;
    export let type = 'text';

    let inputProps = $$props;
    inputProps.type = inputProps.type ?? type
    delete inputProps.class;
    delete inputProps.form;
</script>
<Label>
    {#if type === 'checkbox'}
        <Checkbox value={value} bind:checked={$form[name]} {...inputProps}/>
    {/if}
    <span class:block={type !== 'checkbox'} class:ml-1={type === 'checkbox'}>
        {label}{#if required}<span class="text-xs">*</span>{/if}
    </span>
    {#if type === 'select'}
        <Select bind:value={$form[name]} class="w-full block mt-1" {...inputProps}/>
    {:else if type === 'datetime'}
        <DateTimeInput bind:value={$form[name]} class="w-full block mt-1" {...inputProps}/>
    {:else if type === 'birthdate'}
        <BirthDateInput bind:value={$form[name]} class="w-full block mt-1" {...inputProps}/>
    {:else if type !== 'checkbox'}
        <Input bind:value={$form[name]} class="w-full block mt-1" {...inputProps}/>
    {/if}
    <InputError message={$form.errors[name]}/>

</Label>
