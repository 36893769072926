<script>
    import FormSection from "../Layout/FormSection.svelte";
    import {isBoolean, isNumber} from "lodash/lang.js";
    import Field from "./Field.svelte";
    import PrimaryButton from "../Interactive/PrimaryButton.svelte";

    export let form;

    function capitalize(val) {
        return val.charAt(0).toUpperCase() + val.slice(1)
    }
    function labelFromKey(key){
        return key.split('_').map(capitalize).join(' ')
    }

</script>

<FormSection on:submit>

    <slot slot="title" name="title"/>
    <slot slot="description" name="description"/>

    <div slot="form">
        {#each Object.entries($form.data()) as [key,value]}
            {#if isNumber(value)}
                <Field type="number" bind:form name="{key}" label={labelFromKey(key)}/>
            {:else if isBoolean(value)}
                <Field type="checkbox" bind:form name="{key}" label={labelFromKey(key)}/>
            {:else}
                <Field type="text" bind:form name="{key}" label={labelFromKey(key)}/>
            {/if}
        {/each}
    </div>

    <div slot="actions">
        <PrimaryButton type="submit">
            Speichern
        </PrimaryButton>
    </div>


</FormSection>
