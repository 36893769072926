<script>
    import Route from "./Route.svelte";
</script>

<Route {...$$restProps} class="app-link text-sm font-medium text-primary-600">
    -> <slot/>
</Route>
<style>
    :global(a.app-link) {
        position: relative;
    }

    :global(a.app-link::before) {
        content: "";
        position: absolute;
        display: block;
        width: 100%;
        height: 1px;
        bottom: 0;
        left: 0;
        @apply bg-primary;
        transform: scaleX(0);
        transition: transform 0.3s ease;
    }
    :global(a.app-link:hover::before) {
        transform: scaleX(1);
    }
</style>
