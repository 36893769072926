<script>
    import {page} from "@inertiajs/svelte";
    import Alert from "../../Ui/Display/Alert.svelte";
    import {onDestroy} from "svelte";
    import {flip} from "svelte/animate";

    let autoremove;
    let i = 1;
    let alerts = []
    let unsubscribe = page.subscribe($page => {
        if(!$page.props){
            return;
        }
        let n = 0;
        for(let flash of $page.props.flash){
            let alert = {
                node: null,
                args: flash,
                id: i++
            }
            setTimeout(() => {
                remove(alert)
            }, 3500 + (n++ * 500))
            alerts = [...alerts, alert]
        }
    })
    onDestroy(unsubscribe)

    function remove(alert) {
        alerts = alerts.filter(a => a !== alert)
    }
</script>
<div class="fixed top-0 mt-4 flash-container flex flex-col gap-2 z-30 ">
        {#each alerts as alert (alert.id)}

            <div animate:flip={{duration: 2000}}>
                <Alert {...alert.args} on:close={() => remove(alert)}/>
            </div>


        {/each}
</div>
<style>
    .flash-container {
        margin-left: 25%;
        margin-right: 25%;
        width: 50%;
    }
</style>
