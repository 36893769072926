<script context="module">
    import GuestLayout from "../../../Layouts/GuestLayout.svelte";

    export const layout = GuestLayout;
</script>
<script>
    import PageTitle from "../../../Ui/Typography/PageTitle.svelte";
    import {useForm} from "@inertiajs/svelte";
    import Field from "../../../Ui/Form/Field.svelte";
    import Container from "../../../Ui/Layout/Container.svelte";
    import {localDateShort} from "../../../lib/date.js";
    import PrimaryButton from "../../../Ui/Interactive/PrimaryButton.svelte";

    export let group;
    export let text_notice
    export let text_rules
    export let text_newsletter

    let form = useForm({
        "first_name": null,
        "last_name": null,
        "birth_date": "" + new Date().getFullYear() + "-01-01",
        "email": null,
        "phone": null,
        "street": null,
        "zip": null,
        "country": "Österreich",
        "place": null,
        "marketing_consent": false,
    });

    function submit() {
        $form.post(route("frontend.disclaimer.submit", group.ref));
    }
</script>

<Container>
    <div class="text-center mb-8">
        <PageTitle title="Anmeldung-Haftungsausschluss"/>

        <div class="">
            Datum: {localDateShort(group.start_date)}
        </div>
    </div>


    <form on:submit|preventDefault={submit}>

        <div class="max-w-xl mx-auto flex flex-col gap-4">
            <div>
                <div class="text-lg">
                    Persönliche Angaben zum Spieler
                </div>
                <div class="text-sm">
                    * Plfichtfelder
                </div>
            </div>

            <div class="flex flex-col lg:flex-row gap-4">
                <Field bind:form name="first_name" label="Vorname" required/>
                <Field bind:form name="last_name" label="Nachname" required/>
            </div>

            <Field bind:form name="birth_date" label="Geburtsdatum" type="birthdate" required/>

            <Field bind:form name="street" label="Wohnadresse" required/>

            <div class="flex flex-col lg:flex-row flex-col gap-4">
                <Field bind:form name="zip" label="PLZ" required/>
                <Field bind:form name="place" label="Ort" required/>
            </div>
            <Field bind:form name="country" label="Land" required/>

            <Field bind:form name="phone" label="Mobilnummer"/>

            <Field bind:form name="email" label="E-Mail Adresse" required={$form.marketing_consent} type="email"/>

            <div class="inline-flex">
                <Field bind:form name="marketing_consent" type="checkbox" label=""/>
                <span class="mx-2">{@html text_newsletter}</span>
            </div>

        </div>
        <div class="grid gap-2 lg:grid-cols-2 border rounded my-6 p-4 max-h-[80vh] overflow-auto">
            <div class="prose">
                {@html text_notice}
            </div>
            <div class="prose">
                {@html text_rules}
            </div>
        </div>

        <div class="max-w-xl mx-auto flex flex-col gap-4">
            <div class="text-center">
                Mit meiner Unterschrift bestätige ich die Korrektheit meiner Angaben und die oben angeführten Bestimmungen und Hinweise gelesen, verstanden und akzeptiert zu haben, sowie auf allfällige Gefahren hingewiesen worden zu sein. Des Weiteren akzeptiere ich die AGBs der PAP Event GmbH. <br>
                Mit der Übermittlung bin ich damit einverstanden, dass meine Daten erfasst und laut unserer
                <a class="text-primary-600 hover:underline" target="_blank"
                   href="https://paintballpark-wien.at/datenschutzerklaerung">Datenschutzerklärung</a> gespeichert
                werden.
            </div>


            <div class="text-center">
                <PrimaryButton type="submit" disabled={$form.processing}>
                    Zustimmen & Unterschreiben
                </PrimaryButton>
            </div>
        </div>


    </form>
</Container>
