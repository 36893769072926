<script>
    import TableRow from "../../Ui/Table/TableRow.svelte";
    import TableColumn from "../../Ui/Table/TableColumn.svelte";
    import {localTime} from "../../lib/date.js";
    import ArrowUp from "../../Icons/ArrowUp.svelte";
    import ArrowDown from "../../Icons/ArrowDown.svelte";
    import DisclaimerRow from "./DisclaimerRow.svelte";

    export let all_groups;
    export let groups;
    export let disclaimers;
    export let count_estimated;
    export let start_date;
    export let is_walk_in;
    export let name;
    export let vip;
    export let public_name;
    export let email

    let open = false;

    $: overbooked = count_estimated && count_estimated < disclaimers.length;
    $: complete = count_estimated  && count_estimated === disclaimers.length;

</script>


<TableRow on:click={() => open = !open} class="cursor-pointer {open ? 'bg-blue-100' : ''}" tabindex="0">
    <TableColumn>
        {localTime(start_date)}
    </TableColumn>
    <TableColumn>
        {#if is_walk_in}
            Walk In:
        {/if}
        {#if vip}
            VIP:
        {/if}
        {name}
        {#if vip}
            ({public_name})
        {/if}
    </TableColumn>
    <TableColumn class="text-center {overbooked ? 'text-red-500' : ''} {complete ? 'text-green-500' : ''}">
        {disclaimers.length} /
        {#if count_estimated}
            {count_estimated}
        {:else}
            ∞
        {/if}
    </TableColumn>
    <TableColumn>
        {#if open}
            <ArrowUp/>
        {:else}
            Alle anzeigen <ArrowDown/>
        {/if}
    </TableColumn>
</TableRow>
{#each disclaimers as disclaimer(disclaimer.id)}
    <DisclaimerRow {...disclaimer} {disclaimers} groups={all_groups} {open} />
{/each}
