<script context="module">
    import GuestLayout from "../../../Layouts/GuestLayout.svelte";

    export const layout = GuestLayout;
</script>
<script>

    import PageTitle from "../../../Ui/Typography/PageTitle.svelte";
    import {useForm} from "@inertiajs/svelte";
    import Container from "../../../Ui/Layout/Container.svelte";
    import {localDateShort} from "../../../lib/date.js";

    import Camera from "../../../Icons/Camera.svelte";
    import SignaturePad from "../../../Components/SignaturePad.svelte";
    import {isMobile} from "../../../lib/mobile.js";
    import PrimaryButton from "../../../Ui/Interactive/PrimaryButton.svelte";

    export let disclaimer;
    export let group;
    export let text_signature;

    let signatureComplete = false

    let form = useForm({
        signature: null,
        type: isMobile() ? 'selfie' : 'signature'
    });

    function setType(type){
        $form.type = type
        signatureComplete = false;
    }

    function submit() {
        $form.post(route("frontend.disclaimer.signature.submit", disclaimer.ref), {
            forceFormData: true,
        })
    }


    let files;
    let preview = null;

    function displayPreview() {
        if (files.length > 0) {
            preview = URL.createObjectURL(files[0]);
        }
        $form.signature = files[0]
        signatureComplete = true
    }
</script>
<Container>
    <div class="text-center mb-8">
        <PageTitle title="Haftungsausschluss unterzeichnen"/>
        <p>
            Für {disclaimer.first_name} {disclaimer.last_name}
        </p>

        <div class="">
            Datum: {localDateShort(group.start_date)}
        </div>

    </div>

    <form on:submit|preventDefault={submit}>

        <div class="max-w-xl mx-auto flex flex-col gap-4">
            <div class="prose bg-primary p-4 text-white text-center text-lg">
                {@html text_signature}
            </div>

            {#if $form.type === 'selfie'}
                    <div>
                        <label
                            class="file flex-grow rounded border border-primary hover:bg-primary-100 flex flex-col justify-center items-center gap-4 cursor-pointer p-4">
                            {#if preview}
                                <img src={preview} class="rounded-2xl h-32 w-32 object-cover border-theme-1"/>

                                <div class="bg-primary mt-4 p-4 text-white pointer-events-none rounded">
                                    Selfie ändern
                                </div>
                            {:else}
                        <span class="mt-12 pointer-events-none">
                            <Camera class="w-24 h-24"/>
                        </span>
                                <span class="bg-primary p-4 text-white pointer-events-none rounded">
                            Selfie aufnehmen
                        </span>
                            {/if}
                            <input type="file" bind:files class="invisible max-w-full" on:change={displayPreview}
                                   accept="image/*" capture="user"/>
                        </label>
                    </div>

                    <button
                        class="inline-flex items-center justify-center px-4 py-2 bg-white border border-gray-800 rounded-md font-semibold text-xs text-gray-800 uppercase tracking-widest hover:bg-gray-200 hover:border-gray-600 active:border-gray-900 focus:outline-none focus:border-gray-900 focus:shadow-outline-gray disabled:opacity-25 transition ease-in-out duration-150"
                        type="button" on:click={() => setType('signature')}>
                        Ich möchte lieber Unterschreiben
                    </button>
            {/if}
            {#if $form.type === 'signature'}
                <p class="text-center">
                    Bitte unterschreibe auf der sichtbaren Fläche unterhalb.
                </p>

                <SignaturePad bind:value={$form.signature} on:input={() => signatureComplete = true}/>

                <button
                    class="inline-flex items-center justify-center px-4 py-2 bg-white border border-gray-800 rounded-md font-semibold text-xs text-gray-800 uppercase tracking-widest hover:bg-gray-200 hover:border-gray-600 active:border-gray-900 focus:outline-none focus:border-gray-900 focus:shadow-outline-gray disabled:opacity-25 transition ease-in-out duration-150"
                    type="button" on:click={() => setType('selfie')}>
                    Ich möchte lieber einen Selfi machen
                </button>
            {/if}

            <PrimaryButton type="submit" disabled={!signatureComplete}>
                Bestätigen
            </PrimaryButton>
        </div>
    </form>
</Container>
