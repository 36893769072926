<script>


import PageTitle from "../../Ui/Typography/PageTitle.svelte";
import Container from "../../Ui/Layout/Container.svelte";
import PrimaryButton from "../../Ui/Interactive/PrimaryButton.svelte";
import Label from "../../Ui/Form/Label.svelte";
import {router} from "@inertiajs/svelte";

function syncToMailjet(){
    router.post(route('marketing.newsletter-export.mailjet'))
}

</script>
<Container>
    <PageTitle title="Newsletter Export"/>
    <Label>
        <div class="mb-3">Alle Teilnehmer mit Marketing Consent als CSV herunterladen</div>

        <PrimaryButton href={route('marketing.newsletter-export.csv')} target="_blank">

            Herunterladen
        </PrimaryButton>

        <PrimaryButton on:click={syncToMailjet}>
            Zu Mailjet Synchronisieren
        </PrimaryButton>
    </Label>
</Container>



