<script lang="ts">
    import Container from "../../../Ui/Layout/Container.svelte";
    import {useForm} from "@inertiajs/svelte";
    import AutoForm from "../../../Ui/Form/AutoForm.svelte";

    export let settings;

    let form = useForm(settings);
    const submit = () => $form.put(route('settings.general.update'))
</script>

<Container class="mt-4">
    <AutoForm form={form} on:submit={submit}/>
</Container>
