<script>
    import {onMount} from "svelte";
    import {pageTitle} from "../../stores/page.js";
    //
    export let title = '';
    let clazz = "text-2xl py-2";
    export {clazz as class}
    onMount(() => {
        $pageTitle = title
    })

</script>
<h1 class="{clazz}" {...$$restProps}>
    {title}
</h1>

<!--{#if title}-->
<!--    <div class="bg-white px-2 py-2 border-b border-gray-200">-->
<!--        {title}-->
<!--    </div>-->
<!--{/if}-->
