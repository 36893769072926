<script context="module">
    import AuthLayout from "../../Layouts/AuthLayout.svelte";

    export const layout = AuthLayout;
</script>
<script>
    import {useForm} from "@inertiajs/svelte";
    import Field from "../../Ui/Form/Field.svelte";
    import Submit from "../../Ui/Form/Submit.svelte";
    import Route from "../../Ui/Route.svelte";
    import ApplicationMark from "../../Ui/ApplicationMark.svelte";
    import Form from "../../Ui/Form/Form.svelte";
    import PageTitle from "../../Ui/Typography/PageTitle.svelte";
    import Stack from "../../Ui/Layout/Stack.svelte";
    import AppLink from "../../Ui/AppLink.svelte";

    export let status;
    let form = useForm({
        email: null,
        password: null
    });


    const login = () => {
        $form.post(route("login"));
    };

</script>

<Stack>
    <div class="flex justify-center">
        <ApplicationMark/>
    </div>


    <PageTitle title="Login"/>


    {#if status}
        <div class="mb-4 font-medium text-sm text-green-600">
            {@html status }
        </div>
    {/if}

    <Form on:submit={login}>

        <Field bind:form name="email" label="E-Mail" type="email" placeholder="jane.doe@example.wien"
               required autofocus/>

        <Field bind:form name="password" label="Passwort" type="password"
               placeholder="&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;"
               required
               autocomplete="current-password"/>

        <Submit class="w-full">
            Anmelden
        </Submit>
    </Form>

    <div class="text-center">

    </div>


    <Stack class="text-center gap-4 mt-4 items-center">
        <AppLink to="login.request_onetime">
            Ohne Passwort anmelden.
        </AppLink>
        <AppLink to="password.request">
            Passwort vergessen?
        </AppLink>
    </Stack>
</Stack>
