<script>
    import {createEventDispatcher} from "svelte";
    import {range} from "lodash";
    import Select from "./Select.svelte";
    import Label from "./Label.svelte";
    import {isoDate} from "../../lib/date.js";

    export let value = null;
    export let required = false;
    let clazz;
    export {clazz as class};

    const dispatch = createEventDispatcher();
    const months = {
        0: "Jänner",
        1: "Februar",
        2: "März",
        3: "April",
        4: "Mai",
        5: "Juni",
        6: "Juli",
        7: "August",
        8: "September",
        9: "Oktober",
        10: "Novenber",
        11: "Dezember",
    };
    const years = range(new Date().getFullYear(), new Date().getFullYear() - 100, -1);

    $: availableDays = getAvailableDays(value);

    $: year = value ? datePart(value, "year") : new Date().getFullYear();
    $: month = value ? datePart(value, "month") : null;
    $: day = value ? datePart(value, "day") : null;

    function getAvailableDays(d) {
        let dateItem = new Date(d);
        let daysInMonth = new Date(dateItem.getFullYear(), dateItem.getMonth()+1, 0).getDate();
        return range(1, daysInMonth+1);
    }

    function datePart(val, type) {
        let d = new Date(val);
        if (type === "year") {
            return d.getFullYear();
        }
        if (type === "month") {
            return d.getMonth();
        }
        if (type === "day") {
            return d.getDate();
        }
    }

    function setDatePart(type, val) {

        let d = new Date(value);
        if (type === "year") {
            d.setFullYear(val);
        }
        if (type === "month") {
            d.setMonth(val);
        }
        if (type === "day") {
            d.setDate(val);
        }

        value = isoDate(d);
        dispatch("input");
    }

    let inputProps = $$restProps;
    inputProps.type = null;

</script>
<div class="{clazz} flex gap-2 ">
    <Label>
        <span class="block">Tag</span>
        <Select options={availableDays} on:change={e => setDatePart("day",e.target.value)} emptyOption="Tag" {required}/>
    </Label>
    <Label>
        <span class="block">Monat</span>
        <Select options={months} on:change={e => setDatePart("month",e.target.value)} emptyOption="Monat"  {required}/>
    </Label>
    <Label>
        <span class="block">Jahr</span>
        <select
            class="form-select border-gray-300 focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 rounded-md shadow-sm bg-white  "
            on:change={e => setDatePart("year",e.target.value)}  {required}>
            <option value="">Jahr</option>
            {#each years as year}
                <option value={year}>
                    {year}
                </option>
            {/each}
        </select>

    </Label>
</div>

