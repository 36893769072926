<script>
    export let show = false;

    function hide(){
        show = false;
    }
</script>
<button class:hidden={!show} class="flex items-center justify-center md:pl-64 fixed inset-0 bg-gray-100 bg-opacity-80 z-10" on:click={hide}>
    <div class="modal-content max-w-2xl" on:click|stopPropagation>
        <slot {hide}/>
    </div>
</button>

<style>

</style>
