<script>
    import {router} from "@inertiajs/svelte";
    import ArrowUp from "../../Icons/ArrowUp.svelte";
    import ArrowDown from "../../Icons/ArrowDown.svelte";
    import {sort} from "../../stores/page.js";

    export let column;
    export let direction;

    function handleSort() {
        if($sort.column === column){
            if($sort.direction === 'desc'){
                router.reload({data: {sort: null, direction: null}})
            }else {
                router.reload({data: {sort: column, direction: 'desc'}})
            }
        }else {
            router.reload({data: {sort: column, direction: 'asc'}})
        }
    }
</script>
<div>
    <button class="cursor-pointer"
         on:click={handleSort}>
        <slot/>
        {#if $sort.column === column}
            {#if $sort.direction === 'asc'}
                <ArrowUp class="w-3 h-3 inline-block mx-1"/>
            {:else if $sort.direction === 'desc'}
                <ArrowDown class="w-3 h-3 inline-block mx-1"/>
            {/if}
        {/if}
    </button>
</div>
