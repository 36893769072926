<script>
    import ListSection from "../../../Ui/Layout/ListSection.svelte";
    import FilterSelect from "../../../Ui/Interactive/FilterSelect.svelte";
    import SearchBar from "../../../Ui/Interactive/SearchBar.svelte";
    import Container from "../../../Ui/Layout/Container.svelte";
    import TableHeader from "../../../Ui/Table/TableHeader.svelte";
    import SortableLink from "../../../Ui/Interactive/SortableLink.svelte";
    import TableAction from "../../../Ui/Table/TableAction.svelte";
    import PrimaryButton from "../../../Ui/Interactive/PrimaryButton.svelte";
    import Print from "../../../Icons/Print.svelte";
    import Key from "../../../Icons/Key.svelte";
    import TableRow from "../../../Ui/Table/TableRow.svelte";
    import TableColumn from "../../../Ui/Table/TableColumn.svelte";
    import PageTitle from "../../../Ui/Typography/PageTitle.svelte";
    import Stack from "../../../Ui/Layout/Stack.svelte";
    import Pencil from "../../../Icons/Pencil.svelte";
    import {localDate, localDateTime} from "../../../lib/date.js";
    import Check from "../../../Icons/Check.svelte";
    import Route from "../../../Ui/Route.svelte";
    import Logout from "../../../Icons/Logout.svelte";
    import Link from "../../../Icons/Link.svelte";
    import Eye from "../../../Icons/Eye.svelte";
    import Select from "../../../Ui/Form/Select.svelte";
    import Input from "../../../Ui/Form/Input.svelte";
    import Label from "../../../Ui/Form/Label.svelte";
    import {permissions} from "../../../stores/page.js";

    export let groups;
    export let categories;
    export let category_id;
    export let start_date
</script>
<Container>
    <ListSection list={ groups }>
        <div slot="title">
            <PageTitle title="Gruppen"/>
        </div>
        <div slot="description">Gruppenverwaltung</div>
        <div slot="filter" class="flex gap-4">
            <div>
                <Label for="category_id">Kategorie</Label>
                <Select emptyOption="Alle Kategorien"
                        emptyOptionDisabled={false}
                        emptyOptionValue=""
                        name="category_id"
                        bind:value={category_id}
                        options={categories}>
                </Select>
            </div>
            <div>
                <Label for="start_date">Startdatum</Label>
                <Input type="date" name="start_date" bind:value={start_date} placeholder="Startdatum"/>
            </div>
        </div>

        <div slot="actions">
            <Stack>
                <PrimaryButton route="disclaimer.group.create" class="">
                    Neue Gruppe erstellen
                </PrimaryButton>
                <SearchBar/>
            </Stack>
        </div>

        <TableHeader slot="header">
            <th>
                Kategorie
            </th>
            <th>
                Start
            </th>
            <th>
                Name
            </th>
            <th>
                BuchungsNr.
            </th>
            <th>
                E-Mail
            </th>
            <th>
                Anzahl
            </th>
            <th>
                VIP
            </th>
            <th class="w-20"></th>
        </TableHeader>

        <TableRow slot="item" let:item class="{item.is_walk_in ? 'text-yellow-500': ''}">
            <TableColumn>{item.category.name}</TableColumn>
            <TableColumn>
                {#if item.is_walk_in}
                    {localDate(item.start_date)}
                {:else}
                    {localDateTime(item.start_date)}
                {/if}
            </TableColumn>
            <TableColumn>{item.name}</TableColumn>
            <TableColumn>{item.booking_code ?? '-'}</TableColumn>
            <TableColumn>{item.email}</TableColumn>
            <TableColumn>{item.count_estimated ?? '-'}</TableColumn>
            <TableColumn>
                {#if item.vip}
                    <span title="{item.public_name}">
                        <Check />
                    </span>
                {/if}
            </TableColumn>
            <TableColumn class="flex flex-row gap-4 items-center">
                {#if $permissions.includes('edit groups')}
                    <TableAction  to="disclaimer.group.edit" args={item.id} icon={Pencil}/>
                {/if}
                {#if item.can_show}
                    <TableAction  to="disclaimer.group.show" args={item.id} icon={Eye}/>
                {/if}
                <Route to="frontend.disclaimer.create" args={item.ref} target="_blank" title="Anmeldelink öffnen">
                    <Link/>
                </Route>
            </TableColumn>
        </TableRow>


    </ListSection>

</Container>
